import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface StakedColumnProps {
    apiData? : Object[];
    height? : Number;
    bg? : string;
    isFullscreen? : boolean;
    legendHeight? : number;
    props? : any;
}

const StackedColumnChart: React.FC<StakedColumnProps> = ({apiData, height=210, legendHeight=40, bg, isFullscreen=false, props}) => {

        const [chartOptions, setChartOptions] = useState({
            chart: {
                type : "column",
                backgroundColor : bg || "transparent",
                height : height
            },
            title: {
                text: ''
            },
            xAxis: {
                type : 'datetime'
            },
            yAxis : {
                stackLabels : {
                    enabled : true
                }
            },
            legend: {
                enabled : true,
                align: 'left',
                verticalAlign: 'bottom',
                // x: 0,
                // y: 100,
                maxHeight : legendHeight,
                useHTML : true,
                itemStyle : {
                    overflow : "auto"
                }
            },
            tooltip: {
                shared : true,
                pointFormat: '{series.name}: {point.y}<br/>'//Total: {point.stackTotal}
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            series: [] as Object[],
            credits : {
                enabled : false
            }
        })

        // Adjust chart height based on full-screen state
    useEffect(() => {
        const newHeight = isFullscreen ? 800 : height;
        setChartOptions((prevOptions) => ({
            ...prevOptions,
            chart: {
                ...prevOptions.chart,
                height: newHeight
            }
        }));
    }, [isFullscreen, height]);

        useEffect(() => {
            if (apiData) {
                setChartOptions({
                    ...chartOptions,
                    series: apiData,
                    ...(props || {})
                });
            }
        }, [apiData, props]);
    
        return (
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        );
};

export default StackedColumnChart;
// src/components/Sidebar.tsx
import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  IconButton,
  Icon,
  Collapse,
  useDisclosure,
  FormControl,
  FormLabel,
  Text,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Button,
  Portal,
  VStack,
  Divider,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { CiSliderVertical } from "react-icons/ci";
import { Link, useNavigate, useLocation } from "react-router-dom";
import NavItem from "./NavItem";
import { Home, AlertTicket, Analysis, Site, Tools, Alarm } from "../../assets/Navbar/SideNavBar";
import { Select } from "chakra-react-select";
import { useCustomerOptionsContext } from "../../Context/CustomerOptionsContext";
import { useSelectedCustomerIDContext } from "../../Context/SelectedCustomerIDContext";
import useAuth from "../../Services/Hooks/UseAuth";
import { jwtDecode } from "jwt-decode";
import swal from "sweetalert";
import { VscKebabVertical } from "react-icons/vsc";
import { GetPlantGroup, PlantTimeZoneSelection } from "../../Services/CustomerHandlingService";

interface SidebarContentProps {
  isSidebarExpanded: boolean;
  onToggleSidebar: () => void;
  [x: string]: any;
}

interface JwtHeader {
  firstName: string;
  lastName: string;
  scopes: string[];
  customerId : string;
}

interface PortfolioTypeEntries {
  value : string;
  label : string;
}

const SidebarContent: React.FC<SidebarContentProps> = ({
  isSidebarExpanded,
  onToggleSidebar,
  ...props
}) => {

  // ******************Handle Mobile Logout Button **************** */

  const jwt = localStorage.getItem("token");
  if (!jwt) {
    throw new Error("JWT not found in localStorage");
  }

  let fname = "";
  let lname = "";
  let user = "";
  let customerID = ""

  try {
    const decodedToken: JwtHeader = jwtDecode<JwtHeader>(jwt);
    const { firstName, lastName, scopes, customerId } = decodedToken;
    fname = firstName;
    lname = lastName;
    user = scopes[0];
    customerID = customerId;
  } catch (error) {
    console.error("Error decoding token:", error);
  }

  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  
  const handleLogOut = async () => {
    const response = await logout();
    if (response === true) {
      navigate("/");
    }
  };

  const handleLogOutButton = async () => {
    const response = await swal("Are you sure  you want to Log out?", 
    {
      buttons : ["Cencel", "Log out"]
    })
    if(response) {
      handleLogOut();
    }
  }

  // ************* Handle PortfolioTypeOption **********

  const PortfolioTypeOption = localStorage.getItem("PortfolioType");
  // if(!PortfolioTypeOption) {
  //   throw Error("Portfolio option not found!");
  // }


  //****************Handle Select Customer */
  const { customerOptions, setCustomerOptions } = useCustomerOptionsContext();
  const {selectedCustomerID, setSelectedCustomerID} = useSelectedCustomerIDContext();

  const handlePlantChange = (selectedOption: any) => {
    setSelectedCustomerID(selectedOption?.value);
    localStorage.setItem("SelectedCustomerId", selectedOption?.value || "");
    PlantTimeZoneSelection(selectedOption?.value);
    // window.location.reload();
  };
  // console.log(customerOptions)
  // console.log(selectedCustomerID)
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(null);
  useEffect(() => {
    if (customerOptions) {
      // console.log(customerOptions)
      setSelectedCustomerId(selectedCustomerID);
      localStorage.setItem("SelectedCustomerId", selectedCustomerID || "");
      if(selectedCustomerID) {
        PlantTimeZoneSelection(selectedCustomerID);
      }

    } 
  }, []);

  const portfolio = useDisclosure();
  const sites = useDisclosure();
  const PortfolioType = useDisclosure();
  const Assets = useDisclosure();
  const tools = useDisclosure();
  const showLabels = isSidebarExpanded;

  // ********** Active Links Color ***********
    // Function to determine active link styles
    const isActiveLink = (path: string) => location.pathname === path ? "#19CA16" : "white";

    // ********* Handle Expand All *********

    const HandleExpandAll = () => {
      portfolio.onOpen();
      sites.onOpen();
      PortfolioType.onOpen();
      Assets.onOpen();
      tools.onOpen();
    }
    

    // ********* Handle Collapse All *********
    const handleCollapseAll = () => {
      portfolio.onClose();
      PortfolioType.onClose();
      sites.onClose();
      Assets.onClose();
      tools.onClose();
    };


    // ********** Selected Portfolio Type *********

    const [portfolioType, setPortfolioType] = useState("");
    const currentPlant = PortfolioTypeOption? JSON.parse(PortfolioTypeOption)[0].label : "";
    
    useEffect(() => {

      setPortfolioType(currentPlant);
    }, [])
    
    
    function handlePortfolioType(type : string, id : string) {
      setPortfolioType(type);
      localStorage.setItem("SelectedPortfolioTypeId", id);
      GetPlantGroup(id, setCustomerOptions, setSelectedCustomerID, true);
    }

    const authority = localStorage.getItem("authority");

    const [link, setLink] = useState("");
    function portfolioPermission() {
      if(authority == "Customer Users") {
        swal("Access Denied!", "You don't have permission to access this.", "error");
      } else {
        navigate(link)
      }
    };

    function portfolioLink(Rlink : string) {
      if(authority !== "Customer Users") {
        setLink(Rlink);
      };
    }

  return (
    <Box
      as="nav"
      pos="fixed"
      top="10"
      left="0"
      zIndex="sticky"
      h="full"
      pb="10"
      overflowX="hidden"
      overflowY="auto"
      bg="white"
      _dark={{ bg: "gray.800" }}
      borderRightWidth="1px"
      w={showLabels ? "300px" : "60px"}
      transition="width 0.3s"
      {...props}
    >
      <Flex
        direction="column"
        as="nav"
        mt={2}
        fontSize = {10}
        lineHeight = {"12px"}
        color="gray.600"
        aria-label="Main Navigation"
      >
        {/* <Box display={"inline-flex"}> */}
          <Button
            mt={5}
            ml={2}
            height={"4rem"}
            aria-label="Menu"
            color={"#FFFFFFFF"}
            bg={"transparent"}
            // icon={<FiMenu />}
            display={{ base: "inline-flex", md: "inline-flex" }}
            _hover={{ bg: "transparent" }}
            cursor={"pointer"}
            alignSelf={isSidebarExpanded ? "start" : "center"}
            onClick={onToggleSidebar}
            mb={isSidebarExpanded? 0 : 8}
          > <FiMenu /> </Button>

        {/* </Box> */}
        <Popover aria-label="A Tooltip" placement={"left"}>
          <PopoverTrigger>
            <IconButton
              // mt={5}
              aria-label="Collapse"
              color={"#FFFFFFFF"}
              bg={"transparent"}
              icon={<VscKebabVertical />}
              display={isSidebarExpanded? "inline-flex" : "none"}
              _hover={{ bg: "transparent" }}
              cursor={"pointer"}
              alignSelf={isSidebarExpanded ? "end" : "center"}
              // onClick={onToggleSidebar}
              mb={8}
            />
          </PopoverTrigger>
            <PopoverContent
              style={{ pointerEvents: "none" }} // Allow clicking through
              boxShadow="lg"
              bg="whiteAlpha.900"
              w={"150px"}
            >
              <PopoverArrow />
              {/* <PopoverCloseButton /> */}
              <PopoverBody>
              <VStack style={{ pointerEvents: "auto" }} spacing={2} cursor={"pointer"} w={"100%"} fontSize={12}>
                  <Text onClick={HandleExpandAll} width={"100%"} _active={{color : "blue", fontWeight : 600}} textAlign={"center"}>Expand All</Text>
                  <Divider colorScheme="red" height={1} opacity={1} ></Divider>
                  <Text onClick={handleCollapseAll} width={"100%"} _active={{color : "blue", fontWeight : 600}} textAlign={"center"}>Collapse All</Text>
              </VStack>
              </PopoverBody>
            </PopoverContent>
        </Popover>
        <NavItem
          icon={Home}
          onClick={portfolio.onToggle}
          fontSize = {15}
          lineHeight = {"12px"}
          fontWeight={500}
          showLabel={showLabels}
          color={portfolio.isOpen ? "#19CA16" : "white"}
          alignSelf={isSidebarExpanded? "" : "center"}
        >
          &nbsp; Portfolio
          <Icon
            as={MdKeyboardArrowRight}
            ml="auto"
            transform={portfolio.isOpen ? "rotate(90deg)" : undefined}
          />
        </NavItem>
        <Collapse in={portfolio.isOpen}>
          <NavItem
            ml="12"
            py="2"
            borderLeft = {isSidebarExpanded? "1px solid #FFFFFF" : ""}
            showLabel={showLabels}
            color={isActiveLink("/portfolio")}
            fontWeight={500}
            _activeLink={{
              color : "#19CA16"
            }}
            as={Link}
            to={authority !== "Customer Users" ? link : ""}
            onClick = {() => portfolioPermission()}
            onMouseEnter = {() => portfolioLink("/portfolio")} 
          >
            Dashboard
          </NavItem>
          <NavItem
            pl="3"
            ml="12"
            py="2"
            borderLeft = {isSidebarExpanded? "1px solid #FFFFFF" : ""}
            // icon={AlertTicket}
            // alignSelf={isSidebarExpanded? "" : "center"}
            showLabel={showLabels}
            color={isActiveLink("/alarm")}
            // fontSize={12}
            fontWeight={500}
            as={Link}
            to={authority !== "Customer Users" ? link : ""}
            onClick = {() => portfolioPermission()}
            onMouseEnter = {() => portfolioLink("/alarm")} 
          >
          &nbsp;  Alterts
          </NavItem>
        </Collapse>
        <NavItem
          icon={Site}
          alignSelf={isSidebarExpanded? "" : "center"}
          fontSize = {15}
          lineHeight = {"12px"}
          color={sites.isOpen? "#19CA16" : "white"}
          fontWeight={500}
          showLabel={showLabels}
          onClick={sites.onToggle}
          >
          &nbsp; Sites
          <Icon
              as={MdKeyboardArrowRight}
              transform={sites.isOpen ? "rotate(90deg)" : undefined}
              ml="auto"
          />
        </NavItem>
        <Collapse in={sites.isOpen}>
        <NavItem
          fontSize = {12}
          ml={0}
          lineHeight = {"12px"}
          color={PortfolioType.isOpen? "#19CA16" : "white"}
          fontWeight={500}
          showLabel={showLabels}
          onClick={PortfolioType.onToggle}
          >
          &nbsp; Portfolio Type
          <Icon
              as={MdKeyboardArrowRight}
              transform={PortfolioType.isOpen ? "rotate(90deg)" : undefined}
              ml="auto"
          />
        </NavItem>
        <Collapse in={PortfolioType.isOpen}>
              {(PortfolioTypeOption? JSON.parse(PortfolioTypeOption).map((elem : PortfolioTypeEntries, i : number) => (
                <NavItem key={i} ml="12" borderLeft={isSidebarExpanded? `1px solid ${portfolioType == elem.label? "#19CA16" : "white" }` : ""} py="2" showLabel={showLabels} color={portfolioType == elem.label? "#19CA16" : "white" } onClick={() => handlePortfolioType(elem.label, elem.value)}>
                  {elem.label}
                </NavItem>
              )) : "")}
        </Collapse>
        <FormControl
            ml={showLabels? 5 : 60}
            px={5}
            width={300}
            h={12}
            borderLeft={"1px solid #D1D8DD"}
          >
            <FormLabel
              fontFamily={"inter"}
              fontSize = {10}
              lineHeight = {"12px"}
              fontWeight={50}
              color={"#747474"}
              mt={2}
            >
              Select Plant
            </FormLabel>
            <Box mt={-3} fontWeight={500} zIndex={9999} >
              <Select
                isMulti={false}
                maxMenuHeight={400}
                menuPlacement="bottom"
                onMenuOpen={() => Assets.onOpen()}
                name="plants"
                placeholder="Select Any Plant"
                closeMenuOnSelect={true}
                variant="unstyle"
                focusBorderColor="transparent"
                options={customerOptions || []}
                value={customerOptions?.find(option => option.value === localStorage.getItem("SelectedCustomerId"))}
                onChange={handlePlantChange}
                useBasicStyles={true}
              />
            </Box>
          </FormControl>
          <NavItem
            showLabel={showLabels}
            color={isActiveLink("/plantview")}
            fontSize = {12}
            lineHeight = {"12px"}
            fontWeight={500}
            as={Link} 
            to={'/plantview'}
          >
            &nbsp; Dashboard
            {/* <Icon
              as={MdKeyboardArrowRight}
              ml="auto"
              transform={"rotate(90deg)"}
            /> */}
          </NavItem>
          <NavItem
            onClick={Assets.onToggle}
            showLabel={showLabels}
            color={Assets.isOpen? "#19CA16" : "white"}
            fontSize = {12}
            lineHeight = {"12px"}
            fontWeight={500}
          >
            &nbsp; Assets
            <Icon
              as={MdKeyboardArrowRight}
              ml="auto"
              transform={Assets.isOpen ? "rotate(90deg)" : undefined}
            />
          </NavItem>
          <Collapse in={Assets.isOpen}>
              <NavItem ml="12" borderLeft={isSidebarExpanded? `1px solid ${isActiveLink("/overview")}` : ""} py="2"color={isActiveLink("/overview")} showLabel={showLabels} as={Link} to={'/overview'}>
                Overview
              </NavItem>
              <NavItem ml="12" borderLeft={isSidebarExpanded? `1px solid ${isActiveLink("/plantalarm")}` : ""} py="2"color={isActiveLink("/plantalarm")} showLabel={showLabels} as={Link} to={'/plantalarm'}>
                Alarms
              </NavItem>
              <NavItem ml="12" borderLeft={isSidebarExpanded? `1px solid ${isActiveLink("/inverter")}` : ""} py="2" color={isActiveLink("/inverter")} showLabel={showLabels} as={Link} to={'/inverter'}>
                Inverter
              </NavItem>
              <NavItem ml="12" borderLeft={isSidebarExpanded? `1px solid #FFFFFF}` : ""} py="2" color={"white"} showLabel={showLabels}>
                String
              </NavItem>
              <NavItem ml="12" borderLeft={isSidebarExpanded? `1px solid ${isActiveLink("/grid")}` : ""} py="2" color={isActiveLink("/grid")} showLabel={showLabels} as={Link} to={'/grid'}>
                Grid
              </NavItem>
              <NavItem ml="12" borderLeft={isSidebarExpanded? `1px solid ${isActiveLink("/bess/overview")}` : ""} py="2" color={isActiveLink("/bess/overview")} showLabel={showLabels} as={Link} to={'/bess/overview'}>
                BESS
              </NavItem>
              <NavItem ml="12" borderLeft={isSidebarExpanded? `1px solid ${isActiveLink("/dg")}` : ""} py="2" color={isActiveLink("/dg")} showLabel={showLabels} as={Link} to={'/dg'}>
                Diesel Generator
              </NavItem>
          </Collapse>
        </Collapse>
        <NavItem
          icon={Tools}
          alignSelf={isSidebarExpanded? "" : "center"}
          fontSize = {15}
          lineHeight = {"12px"}
          color={tools.isOpen? "#19CA16" : "white"}
          fontWeight={500}
          onClick={tools.onToggle}
          showLabel={showLabels}
          mb={5}
        >
          &nbsp; Tools
          <Icon
              as={MdKeyboardArrowRight}
              ml="auto"
              transform={tools.isOpen? "rotate(90deg)" : undefined}
            />
        </NavItem>
        <Collapse in={tools.isOpen}>
          <NavItem
            fontSize = {10}
            lineHeight = {"12px"}
            ml="12"
            borderLeft={isSidebarExpanded? "1px solid #FFFFFF" : ""} 
            py="2" 
            color={isActiveLink("/report")}
            fontWeight={500}
            showLabel={showLabels}
            as={Link}
            to={"/report"}
          >
          Reports
          </NavItem>
          <NavItem
            showLabel={showLabels}
            
            color={"#FFFFFFFF"}
            fontSize = {10}
            lineHeight = {"12px"}
            ml="12"
            fontWeight={500}
            borderLeft={isSidebarExpanded? "1px solid #FFFFFF" : ""} 
            >
            Analytics
          </NavItem>
          <NavItem
            showLabel={showLabels}
            
            color={"#FFFFFFFF"}
            fontSize = {10}
            lineHeight = {"12px"}
            ml="12"
            fontWeight={600}
            borderLeft={isSidebarExpanded? "1px solid #FFFFFF" : ""} 
          >
          Intel-IQ
          </NavItem>
        </Collapse>
        <Flex alignItems="flex-end" display={["flex", "none"]}>      
          <Box
                    ml={1}
                    bg={"#8842E0"}
                    px={4}
                    py={2}
                    borderRadius={"15%"}
                    position={"absolute"}
                    bottom={Assets.isOpen && isSidebarExpanded? 50 : 20}
                    display={"inline-flex"}
                    alignSelf={"center"}
                  >
                    <Text
                      fontSize = {16}
                      lineHeight = {"16px"}
                      color={"#ffffff"}
                      textTransform="uppercase"
                      fontWeight="600"
                      letterSpacing="1px"
                      fontFamily="sans-serif"
                      onClick={handleLogOutButton}
                    >
                      {fname[0]}
                    </Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default SidebarContent;

// src/components/Sidebar.tsx
// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Flex,
//   IconButton,
//   Icon,
//   Collapse,
//   useDisclosure,
//   FormControl,
//   FormLabel,
//   Text,
//   Tooltip,
//   Popover,
//   PopoverTrigger,
//   PopoverContent,
//   PopoverArrow,
//   PopoverCloseButton,
//   PopoverBody,
//   Button,
//   Portal,
//   VStack,
//   Divider,
// } from "@chakra-ui/react";
// import { FiMenu } from "react-icons/fi";
// import { MdKeyboardArrowRight } from "react-icons/md";
// import { CiSliderVertical } from "react-icons/ci";
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import NavItem from "./NavItem";
// import { Home, AlertTicket, Analysis, Site, Tools, Alarm } from "../../assets/Navbar/SideNavBar";
// import { Select } from "chakra-react-select";
// import { useCustomerOptionsContext } from "../../Context/CustomerOptionsContext";
// import { useSelectedCustomerIDContext } from "../../Context/SelectedCustomerIDContext";
// import useAuth from "../../Services/Hooks/UseAuth";
// import { jwtDecode } from "jwt-decode";
// import swal from "sweetalert";
// import { VscKebabVertical } from "react-icons/vsc";
// import { GetPlantGroup, PlantTimeZoneSelection } from "../../Services/CustomerHandlingService";
// import { EnlargeWidgetSize, EnlargeWidgetSizeWidth } from "../../Services/EnlargeWidget";

// interface SidebarContentProps {
//   isSidebarExpanded: boolean;
//   onToggleSidebar: () => void;
//   [x: string]: any;
// }

// interface JwtHeader {
//   firstName: string;
//   lastName: string;
//   scopes: string[];
//   customerId : string;
// }

// interface PortfolioTypeEntries {
//   value : string;
//   label : string;
// }

// const SidebarContent: React.FC<SidebarContentProps> = ({
//   isSidebarExpanded,
//   onToggleSidebar,
//   ...props
// }) => {

//   // ******************Handle Mobile Logout Button **************** */

//   const jwt = localStorage.getItem("token");
//   if (!jwt) {
//     throw new Error("JWT not found in localStorage");
//   }

//   let fname = "";
//   let lname = "";
//   let user = "";
//   let customerID = ""

//   try {
//     const decodedToken: JwtHeader = jwtDecode<JwtHeader>(jwt);
//     const { firstName, lastName, scopes, customerId } = decodedToken;
//     fname = firstName;
//     lname = lastName;
//     user = scopes[0];
//     customerID = customerId;
//   } catch (error) {
//     console.error("Error decoding token:", error);
//   }

//   const navigate = useNavigate();
//   const location = useLocation();
//   const { logout } = useAuth();
  
//   const handleLogOut = async () => {
//     const response = await logout();
//     if (response === true) {
//       navigate("/");
//     }
//   };

//   const handleLogOutButton = async () => {
//     const response = await swal("Are you sure  you want to Log out?", 
//     {
//       buttons : ["Cencel", "Log out"]
//     })
//     if(response) {
//       handleLogOut();
//     }
//   }

//   // ************* Handle PortfolioTypeOption **********

//   const PortfolioTypeOption = localStorage.getItem("PortfolioType");
//   // if(!PortfolioTypeOption) {
//   //   throw Error("Portfolio option not found!");
//   // }


//   //****************Handle Select Customer */
//   const { customerOptions, setCustomerOptions } = useCustomerOptionsContext();
//   const {selectedCustomerID, setSelectedCustomerID} = useSelectedCustomerIDContext();

//   const handlePlantChange = (selectedOption: any) => {
//     setSelectedCustomerID(selectedOption?.value);
//     localStorage.setItem("SelectedCustomerId", selectedOption?.value || "");
//     PlantTimeZoneSelection(selectedOption?.value);
//     // window.location.reload();
//   };
//   // console.log(customerOptions)
//   // console.log(selectedCustomerID)
//   const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(null);
//   useEffect(() => {
//     if (customerOptions) {
//       // console.log(customerOptions)
//       setSelectedCustomerId(selectedCustomerID);
//       localStorage.setItem("SelectedCustomerId", selectedCustomerID || "");
//       if(selectedCustomerID) {
//         PlantTimeZoneSelection(selectedCustomerID);
//       }

//     } 
//   }, []);

//   const portfolio = useDisclosure();
//   const sites = useDisclosure();
//   const PortfolioType = useDisclosure();
//   const Assets = useDisclosure();
//   const tools = useDisclosure();
//   const showLabels = isSidebarExpanded;

//   // ********** Active Links Color ***********
//     // Function to determine active link styles
//     const isActiveLink = (path: string) => location.pathname === path ? "#19CA16" : "white";

//     // ********* Handle Expand All *********

//     const HandleExpandAll = () => {
//       portfolio.onOpen();
//       sites.onOpen();
//       PortfolioType.onOpen();
//       Assets.onOpen();
//       tools.onOpen();
//     }
    

//     // ********* Handle Collapse All *********
//     const handleCollapseAll = () => {
//       portfolio.onClose();
//       PortfolioType.onClose();
//       sites.onClose();
//       Assets.onClose();
//       tools.onClose();
//     };


//     // ********** Selected Portfolio Type *********

//     const [portfolioType, setPortfolioType] = useState("");
//     const currentPlant = PortfolioTypeOption? JSON.parse(PortfolioTypeOption)[0].label : "";
    
//     useEffect(() => {

//       setPortfolioType(currentPlant);
//     }, [])
    
    
//     function handlePortfolioType(type : string, id : string) {
//       setPortfolioType(type);
//       localStorage.setItem("SelectedPortfolioTypeId", id);
//       GetPlantGroup(id, setCustomerOptions, setSelectedCustomerID, true);
//     }

//     const authority = localStorage.getItem("authority");

//     const [link, setLink] = useState("");
//     function portfolioPermission() {
//       if(authority == "Customer Users") {
//         swal("Access Denied!", "You don't have permission to access this.", "error");
//       } else {
//         navigate(link)
//       }
//     };

//     function portfolioLink(Rlink : string) {
//       if(authority !== "Customer Users") {
//         setLink(Rlink);
//       };
//     }

//   return (
//     <Box
//       as="nav"
//       pos="fixed"
//       top="10"
//       left="0"
//       zIndex="sticky"
//       h="full"
//       pb="10"
//       overflowX="hidden"
//       overflowY="auto"
//       bg="white"
//       _dark={{ bg: "gray.800" }}
//       borderRightWidth="1px"
//       w={showLabels ? EnlargeWidgetSizeWidth(300) + "px" : window.screen.width > 1530 ? EnlargeWidgetSizeWidth(50) + "px" : EnlargeWidgetSizeWidth(60) + "px"}
//       transition="width 0.3s"
//       {...props}
//     >
//       <Flex
//         direction="column"
//         as="nav"
//         mt={2}
//         fontSize = {EnlargeWidgetSize(10)}
//         lineHeight = {EnlargeWidgetSize(12) + "px"}
//         color="gray.600"
//         aria-label="Main Navigation"
//       >
//         {/* <Box display={"inline-flex"}> */}
//           <Button
//             mt={EnlargeWidgetSize(30)}
//             ml={2}
//             height={EnlargeWidgetSize(4) + "rem"}
//             aria-label="Menu"
//             color={"#FFFFFFFF"}
//             bg={"transparent"}
//             // icon={<FiMenu />}
//             display={{ base: "inline-flex", md: "inline-flex" }}
//             _hover={{ bg: "transparent" }}
//             cursor={"pointer"}
//             alignSelf={isSidebarExpanded ? "start" : "center"}
//             onClick={onToggleSidebar}
//             mb={isSidebarExpanded? 0 : 8}
//           > <FiMenu fontSize={EnlargeWidgetSize(12)} /> </Button>

//         {/* </Box> */}
//         <Popover aria-label="A Tooltip" placement={"left"}>
//           <PopoverTrigger>
//             <IconButton
//               // mt={5}
//               aria-label="Collapse"
//               color={"#FFFFFFFF"}
//               bg={"transparent"}
//               icon={<VscKebabVertical />}
//               display={isSidebarExpanded? "inline-flex" : "none"}
//               _hover={{ bg: "transparent" }}
//               cursor={"pointer"}
//               alignSelf={isSidebarExpanded ? "end" : "center"}
//               // onClick={onToggleSidebar}
//               mb={8}
//             />
//           </PopoverTrigger>
//             <PopoverContent
//               style={{ pointerEvents: "none" }} // Allow clicking through
//               boxShadow="lg"
//               bg="whiteAlpha.900"
//               w={EnlargeWidgetSizeWidth(150) + "px"}
//             >
//               <PopoverArrow />
//               {/* <PopoverCloseButton /> */}
//               <PopoverBody>
//               <VStack style={{ pointerEvents: "auto" }} spacing={2} cursor={"pointer"} w={"100%"} fontSize={EnlargeWidgetSize(12)}>
//                   <Text onClick={HandleExpandAll} width={"100%"} _active={{color : "blue", fontWeight : 600}} textAlign={"center"}>Expand All</Text>
//                   <Divider colorScheme="red" height={1} opacity={1} ></Divider>
//                   <Text onClick={handleCollapseAll} width={"100%"} _active={{color : "blue", fontWeight : 600}} textAlign={"center"}>Collapse All</Text>
//               </VStack>
//               </PopoverBody>
//             </PopoverContent>
//         </Popover>
//         <NavItem
//           icon={Home}
//           onClick={portfolio.onToggle}
//           fontSize = {EnlargeWidgetSize(15)}
//           lineHeight = {EnlargeWidgetSize(12) + "px"}
//           fontWeight={500}
//           showLabel={showLabels}
//           color={portfolio.isOpen ? "#19CA16" : "white"}
//           alignSelf={isSidebarExpanded? "" : "center"}
//         >
//           &nbsp; Portfolio
//           <Icon
//             as={MdKeyboardArrowRight}
//             ml="auto"
//             transform={portfolio.isOpen ? "rotate(90deg)" : undefined}
//           />
//         </NavItem>
//         <Collapse in={portfolio.isOpen}>
//           <NavItem
//             ml="12"
//             py="2"
//             borderLeft = {isSidebarExpanded? "1px solid #FFFFFF" : ""}
//             showLabel={showLabels}
//             color={isActiveLink("/portfolio")}
//             fontWeight={500}
//             _activeLink={{
//               color : "#19CA16"
//             }}
//             as={Link}
//             to={authority !== "Customer Users" ? link : ""}
//             onClick = {() => portfolioPermission()}
//             onMouseEnter = {() => portfolioLink("/portfolio")} 
//           >
//             Dashboard
//           </NavItem>
//           <NavItem
//             pl="3"
//             ml="12"
//             py="2"
//             borderLeft = {isSidebarExpanded? "1px solid #FFFFFF" : ""}
//             // icon={AlertTicket}
//             // alignSelf={isSidebarExpanded? "" : "center"}
//             showLabel={showLabels}
//             color={isActiveLink("/alarm")}
//             // fontSize={12}
//             fontWeight={500}
//             as={Link}
//             to={authority !== "Customer Users" ? link : ""}
//             onClick = {() => portfolioPermission()}
//             onMouseEnter = {() => portfolioLink("/alarm")} 
//           >
//           &nbsp;  Alterts
//           </NavItem>
//         </Collapse>
//         <NavItem
//           icon={Site}
//           alignSelf={isSidebarExpanded? "" : "center"}
//           fontSize = {15}
//           lineHeight = {"12px"}
//           color={sites.isOpen? "#19CA16" : "white"}
//           fontWeight={500}
//           showLabel={showLabels}
//           onClick={sites.onToggle}
//           >
//           &nbsp; Sites
//           <Icon
//               as={MdKeyboardArrowRight}
//               transform={sites.isOpen ? "rotate(90deg)" : undefined}
//               ml="auto"
//           />
//         </NavItem>
//         <Collapse in={sites.isOpen}>
//         <NavItem
//           fontSize = {EnlargeWidgetSize(12)}
//           ml={0}
//           lineHeight = {"12px"}
//           color={PortfolioType.isOpen? "#19CA16" : "white"}
//           fontWeight={500}
//           showLabel={showLabels}
//           onClick={PortfolioType.onToggle}
//           >
//           &nbsp; Portfolio Type
//           <Icon
//               as={MdKeyboardArrowRight}
//               transform={PortfolioType.isOpen ? "rotate(90deg)" : undefined}
//               ml="auto"
//           />
//         </NavItem>
//         <Collapse in={PortfolioType.isOpen}>
//               {(PortfolioTypeOption? JSON.parse(PortfolioTypeOption).map((elem : PortfolioTypeEntries, i : number) => (
//                 <NavItem key={i} ml="12" borderLeft={isSidebarExpanded? `1px solid ${portfolioType == elem.label? "#19CA16" : "white" }` : ""} py="2" showLabel={showLabels} color={portfolioType == elem.label? "#19CA16" : "white" } onClick={() => handlePortfolioType(elem.label, elem.value)}>
//                   {elem.label}
//                 </NavItem>
//               )) : "")}
//         </Collapse>
//         <FormControl
//             ml={showLabels? 5 : 60}
//             px={5}
//             width={EnlargeWidgetSizeWidth(300)}
//             h={(12)}
//             borderLeft={"1px solid #D1D8DD"}
//           >
//             <FormLabel
//               fontFamily={"inter"}
//               fontSize = {EnlargeWidgetSize(10)}
//               lineHeight = {"12px"}
//               fontWeight={50}
//               color={"#747474"}
//               mt={2}
//             >
//               Select Plant
//             </FormLabel>
//             <Box mt={-3} fontWeight={500} zIndex={9999} >
//               <Select
//                 isMulti={false}
//                 maxMenuHeight={400}
//                 menuPlacement="bottom"
//                 onMenuOpen={() => Assets.onOpen()}
//                 name="plants"
//                 placeholder="Select Any Plant"
//                 closeMenuOnSelect={true}
//                 variant="unstyle"
//                 focusBorderColor="transparent"
//                 options={customerOptions || []}
//                 value={customerOptions?.find(option => option.value === localStorage.getItem("SelectedCustomerId"))}
//                 onChange={handlePlantChange}
//                 useBasicStyles={true}
//               />
//             </Box>
//           </FormControl>
//           <NavItem
//             showLabel={showLabels}
//             color={isActiveLink("/plantview")}
//             fontSize = {12}
//             lineHeight = {"12px"}
//             fontWeight={500}
//             as={Link} 
//             to={'/plantview'}
//           >
//             &nbsp; Dashboard
//             {/* <Icon
//               as={MdKeyboardArrowRight}
//               ml="auto"
//               transform={"rotate(90deg)"}
//             /> */}
//           </NavItem>
//           <NavItem
//             onClick={Assets.onToggle}
//             showLabel={showLabels}
//             color={Assets.isOpen? "#19CA16" : "white"}
//             fontSize = {EnlargeWidgetSize(12)}
//             lineHeight = {"12px"}
//             fontWeight={500}
//           >
//             &nbsp; Assets
//             <Icon
//               as={MdKeyboardArrowRight}
//               ml="auto"
//               transform={Assets.isOpen ? "rotate(90deg)" : undefined}
//             />
//           </NavItem>
//           <Collapse in={Assets.isOpen}>
//               <NavItem ml="12" borderLeft={isSidebarExpanded? `1px solid ${isActiveLink("/overview")}` : ""} py="2"color={isActiveLink("/overview")} showLabel={showLabels} as={Link} to={'/overview'}>
//                 Overview
//               </NavItem>
//               <NavItem ml="12" borderLeft={isSidebarExpanded? `1px solid ${isActiveLink("/plantalarm")}` : ""} py="2"color={isActiveLink("/plantalarm")} showLabel={showLabels} as={Link} to={'/plantalarm'}>
//                 Alarms
//               </NavItem>
//               <NavItem ml="12" borderLeft={isSidebarExpanded? `1px solid ${isActiveLink("/inverter")}` : ""} py="2" color={isActiveLink("/inverter")} showLabel={showLabels} as={Link} to={'/inverter'}>
//                 Inverter
//               </NavItem>
//               <NavItem ml="12" borderLeft={isSidebarExpanded? `1px solid #FFFFFF}` : ""} py="2" color={"white"} showLabel={showLabels}>
//                 String
//               </NavItem>
//               <NavItem ml="12" borderLeft={isSidebarExpanded? `1px solid ${isActiveLink("/grid")}` : ""} py="2" color={isActiveLink("/grid")} showLabel={showLabels} as={Link} to={'/grid'}>
//                 Grid
//               </NavItem>
//               <NavItem ml="12" borderLeft={isSidebarExpanded? `1px solid ${isActiveLink("/bess/overview")}` : ""} py="2" color={isActiveLink("/bess/overview")} showLabel={showLabels} as={Link} to={'/bess/overview'}>
//                 BESS
//               </NavItem>
//               <NavItem ml="12" borderLeft={isSidebarExpanded? `1px solid ${isActiveLink("/dg")}` : ""} py="2" color={isActiveLink("/dg")} showLabel={showLabels} as={Link} to={'/dg'}>
//                 Diesel Generator
//               </NavItem>
//           </Collapse>
//         </Collapse>
//         <NavItem
//           icon={Tools}
//           alignSelf={isSidebarExpanded? "" : "center"}
//           fontSize = {EnlargeWidgetSize(15)}
//           lineHeight = {"12px"}
//           color={tools.isOpen? "#19CA16" : "white"}
//           fontWeight={500}
//           onClick={tools.onToggle}
//           showLabel={showLabels}
//           mb={5}
//         >
//           &nbsp; Tools
//           <Icon
//               as={MdKeyboardArrowRight}
//               ml="auto"
//               transform={tools.isOpen? "rotate(90deg)" : undefined}
//             />
//         </NavItem>
//         <Collapse in={tools.isOpen}>
//           <NavItem
//             fontSize = {EnlargeWidgetSize(10)}
//             lineHeight = {EnlargeWidgetSize(12) + "px"}
//             ml="12"
//             borderLeft={isSidebarExpanded? "1px solid #FFFFFF" : ""} 
//             py="2" 
//             color={isActiveLink("/report")}
//             fontWeight={500}
//             showLabel={showLabels}
//             as={Link}
//             to={"/report"}
//           >
//           Reports
//           </NavItem>
//           <NavItem
//             showLabel={showLabels}
            
//             color={"#FFFFFFFF"}
//             fontSize = {EnlargeWidgetSize(10)}
//             lineHeight = {EnlargeWidgetSize(12) + "px"}
//             ml="12"
//             fontWeight={500}
//             borderLeft={isSidebarExpanded? "1px solid #FFFFFF" : ""} 
//             >
//             Analytics
//           </NavItem>
//           <NavItem
//             showLabel={showLabels}
            
//             color={"#FFFFFFFF"}
//             fontSize = {EnlargeWidgetSize(10)}
//             lineHeight = {EnlargeWidgetSize(12) + "px"}
//             ml="12"
//             fontWeight={600}
//             borderLeft={isSidebarExpanded? "1px solid #FFFFFF" : ""} 
//           >
//           Intel-IQ
//           </NavItem>
//         </Collapse>
//         {/* Log Out For Mobile (Small Device) */}
//         <Flex alignItems="flex-end" display={["flex", "none"]}>      
//           <Box
//                     ml={1}
//                     bg={"#8842E0"}
//                     px={4}
//                     py={2}
//                     borderRadius={"15%"}
//                     position={"absolute"}
//                     bottom={Assets.isOpen && isSidebarExpanded? 50 : 20}
//                     display={"inline-flex"}
//                     alignSelf={"center"}
//                   >
//                     <Text
//                       fontSize = {EnlargeWidgetSize(16)}
//                       lineHeight = {"16px"}
//                       color={"#ffffff"}
//                       textTransform="uppercase"
//                       fontWeight="600"
//                       letterSpacing="1px"
//                       fontFamily="sans-serif"
//                       onClick={handleLogOutButton}
//                     >
//                       {fname[0]}
//                     </Text>
//           </Box>
//         </Flex>
//       </Flex>
//     </Box>
//   );
// };

// export default SidebarContent;


import { 
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Grid,
    GridItem,
    SimpleGrid,
} from '@chakra-ui/react'
import { FaCaretRight, FaChartLine } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import ChartLayout from '../components/Layouts/ChartLayouts/ChartLayout'
import LineChart from '../components/widgets/charts/LineChart'
import BatteryDigitalProgressBar from '../assets/BESS/KPI/BatteryDigitalProgressBar'
import BarLineChart from '../components/widgets/charts/BarLineChart'
import { useTimeHandle } from '../Services/TimeWindowSetting'
import UseBESSDaily from '../Services/Hooks/Battery/UseBESSDaily'
import { useEffect } from 'react'
import UseBatteryStatus from '../Services/Hooks/Battery/UseBatteryStatus'
import { FcLineChart } from 'react-icons/fc'
import PlantViewTableLayout from '../components/Layouts/TableLayouts/PlantViewTableLayout'
import PlantTable from '../components/widgets/tables/PlantTable'
import UsePlanViewTable from '../Services/Hooks/PlantView/UsePlantViewTable'
import TopRibbon from '../components/TopRibbon'
import { html } from 'gridjs'
import ColumnChart from '../components/widgets/charts/ColumnChart'
import TimeDisplay from '../components/TimeDisplay'
import LandingTimeService from '../Services/LandingTimeService'
import { EnlargeWidgetContent, EnlargeWidgetSize } from '../Services/EnlargeWidget'

const BESS_KPIDashboard = () => {
    // ************ Landing Time Process **********
    LandingTimeService();

    // ******************BESS Cumulative *******************
    const {
        timeWindow: timeWindowCumulative,
        handleTimeWindowChange: handleTimeWindowCumulativeChange,
        handleReset: CumulativeHandleReset
    } = useTimeHandle(5, "hour", "AVG", [1, "hour"]);

    var searchTagCumulative = { 
        devName : "Inverter-1",
        keys: "B1_Inverter_Inverter_1_DC_String2_Volt,B1_Inverter_Inverter_1_DC_String3_Volt",
        type : ["spline"],
        name : ["MIN", "MAX"]
    };
    const CumulativeColor = ["#3853A5", "#F4B725"];
    const CumulativeData = UseBESSDaily(searchTagCumulative, timeWindowCumulative)?.map((series : object, index : number) => ({
        ...series,
        color : CumulativeColor[index]
    }));
    // useEffect(() => {
    //     if (CumulativeData) {
    //         console.log("CumulativeData:", CumulativeData);
    //     }
    // }, [CumulativeData]);

    // ************************ DailDischargey********************
    const {
        timeWindow: timeWindowDailyDischarge,
        handleTimeWindowChange: handleTimeWindowDailyDischargeChange,
        handleReset: DailyDischargeHandleReset
    } = useTimeHandle(7, "day", "AVG", [1, "day"]);
    
    var searchTagDailyDischarge = { 
        devName : "PCS",
        keys: "ESS_SOC,ESS_DOD,ESS_SOH,kWhdischarged_Day",
        type : ["column", "column", "column", "spline"],
        name : ["SOC", "DOD", "SOH", "Discharge Energy"]
    };
    const DailyDischargeColor = ["#3853A5" , "#B11F24", "#F4B725","#01875A", "#F4B725"];
    const DailyDischargeData = UseBESSDaily(searchTagDailyDischarge, timeWindowDailyDischarge)?.map((series : object, index : number) => ({
        ...series,
        color : DailyDischargeColor[index]
    }));
    // useEffect(() => {
    //     if (DailyDischargeData) {
    //         console.log("DailyDischargeData:", DailyDischargeData);
    //     }
    // }, [DailyDischargeData]);

    // **************************** CARDS ******************

    var search = {
        devName : "PCS",
        keys : "ESS_SOC,kWhdischarged_Day,ESS_Rated_Ah,ESS_DC_Amps",
    }
    const batteryStatus = UseBatteryStatus(search) || [];

    var search1 = {
        devName : "PCS",
        keys : "ESS_SOC",
        special : "7daysSOCavg"
    }
    const batteryStatusSoc = UseBatteryStatus(search1) || [];
    // console.log(1, batteryStatusSoc)

    var search2 = {
        devName : "inverter-1",
        keys : "B1_Inverter_Inverter_1_DC_String1_Volt,",
        special : "temp30"
    }
    const batteryStatus2 = UseBatteryStatus(search2) || [];
    // console.log(2, batteryStatus2)



        // ********************* Voltage & Hz *********************
        const {
            timeWindow: timeWindowVolt,
            handleTimeWindowChange: handleTimeWindowVoltChange,
            handleReset: VoltHandleReset
        } = useTimeHandle(1, "cdsf", "AVG", [5, "minute"]);
    
        
        var searchTagVolt = { 
            devName : "PCS", // cal
            keys : "ESS_Ph_AB_Volt_Total_PCS,ESS_Ph_BC_Volt_Total_PCS,ESS_Ph_CA_Volt_Total_PCS,HZ_L1,HZ_L2,HZ_L3",
            type : ["spline","spline","spline","spline", "spline", "spline"],
            name : ["line-1 V","line-2 V","line-3 V","L1 Hz","L2 Hz","L3 Hz"]
        };
        const VoltData = UseBESSDaily(searchTagVolt, timeWindowVolt);
        // useEffect(() => {
        //     if (VoltData) {
        //         console.log("VoltData:", VoltData);
        //     }
        // }, [VoltData]);
    
        // ********************* Grid Current *********************
        const {
            timeWindow: timeWindowGridCurrent,
            handleTimeWindowChange: handleTimeWindowGridCurrentChange,
            handleReset: GridCurrentHandleReset
        } = useTimeHandle(1, "cdsf", "AVG", [5, "minute"]);
    
        
        var searchTagGridCurrent = { 
            devName : "PCS",
            keys : "ESS_Ph_A_Amps_Total_PCS,ESS_Ph_B_Amps_Total_PCS,ESS_Ph_C_Amps_Total_PCS,BESS1_PF",
            type : ["spline","spline","spline","spline"],
            name : ["L1 AMP","L2 AMP","L3 AMP","PF"]
        };
        const GridCurrentData = UseBESSDaily(searchTagGridCurrent, timeWindowGridCurrent);
        // useEffect(() => {
        //     if (GridPowerData) {
        //         console.log("GridPowerData:", GridPowerData);
        //     }
        // }, [GridPowerData]);

        // ******************** PCS Table ***********************
    
    var searchPCSTable = {
        PCS : "AC_Active_Power_Watt,AC_Reactive_Power_var,ESS_Ph_AB_Volt_Total_PCS,ESS_Ph_BC_Volt_Total_PCS,ESS_Ph_CA_Volt_Total_PCS,ESS_Ph_A_Amps_Total_PCS,ESS_Ph_B_Amps_Total_PCS,ESS_Ph_C_Amps_Total_PCS"
    }
    var PCSColumn = [{name : "Name", formatter : (cell : any) => html(`<center>${cell}</center>`)}, "Power kW", "Reactive Power", "Volt-1", "Volt-2", "Volt-3", "L1 Amps", "L2 Amps", "L3 Amps"]
    var PCSFirstColName = "ESS"
    const PCSTableData = UsePlanViewTable(searchPCSTable, PCSFirstColName) as any;

  return (
    <Box maxW="full" ml={10} px={{ base: 2, sm: 12, md: 17 }}>
        <Flex justify={"space-between"} >
            <Breadcrumb spacing="8px" separator={<FaCaretRight color="gray.500" />} mb={5}>
                    <BreadcrumbItem color="rgba(0, 79, 134, 1)" fontSize={EnlargeWidgetSize(12)}>
                        <BreadcrumbLink>Home</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontSize={EnlargeWidgetSize(12)} as={Link} to="/portfolio">
                        Portfolio
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontWeight={600} fontSize={EnlargeWidgetSize(12)}>
                        Sites
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontWeight={600} fontSize={EnlargeWidgetSize(12)} as={Link}>
                        BESS
                        </BreadcrumbLink>
                    </BreadcrumbItem>
            </Breadcrumb>

            <TimeDisplay />
        </Flex>

{/* ****************** TOP RIBBON ************ */}

        <TopRibbon />

        <Grid
            templateRows={["repeat(1, 1fr)","repeat(2, 1fr)"]}
            templateColumns={["repeat(1, 1fr)","repeat(4, 1fr)"]}
            gap={1}
            mt={5}
            ml={[-3,0]}
        >
            <GridItem colSpan={1} rowSpan={1} height={"auto"} width={"auto"}>
                <BatteryDigitalProgressBar
                    title='Depth of Discharge'
                    value={`${100 - batteryStatus[0]}%`}
                />
            </GridItem>
            <GridItem colSpan={1} rowSpan={1} height={"auto"} width={"auto"}>
                <BatteryDigitalProgressBar
                    title='DC Discharge Energy'
                    value={`${batteryStatus[1]} kWh`}
                />
            </GridItem>
            <GridItem colSpan={1} rowSpan={1} height={"auto"} width={"auto"}>
                <BatteryDigitalProgressBar
                    title='Reliability'
                    value={`${((batteryStatus[2] - (2*5)*100)/24).toFixed(2)}%`}
                />
            </GridItem>
            <GridItem colSpan={1} rowSpan={1} height={"auto"} width={"auto"}>
                <BatteryDigitalProgressBar
                    title='Availability'
                    value={`${(batteryStatus[2] * 100) / 24}%`}
                />
            </GridItem>
            <GridItem colSpan={1} rowSpan={1} height={"auto"} width={"auto"}>
                <BatteryDigitalProgressBar
                    title='Average SOC'
                    value={`${batteryStatusSoc[0]}%`}
                />
            </GridItem>
            <GridItem colSpan={1} rowSpan={1} height={"auto"} width={"auto"}>
                <BatteryDigitalProgressBar
                    title='Resting SOC'
                    value={`${batteryStatus[2]}%`}
                />
            </GridItem>
            <GridItem colSpan={1} rowSpan={1} height={"auto"} width={"auto"}>
                <BatteryDigitalProgressBar
                    title='Average C-Rate'
                    value={`${parseFloat((batteryStatus[3] / batteryStatus[2]).toFixed(2))}%`}
                />
            </GridItem>
            <GridItem colSpan={1} rowSpan={1} height={"auto"} width={"auto"}>
                <BatteryDigitalProgressBar
                    title='Temperature within Warranty Range'
                    value={`${batteryStatus2}%`}
                />
            </GridItem>
            
        </Grid>
        <SimpleGrid
            minChildWidth={["300px","500px"]}
            gap={1}
            mt={0}
            maxW={"9xl"}
        >
            <GridItem >
                <ChartLayout
                    title='Daily Discharge Energy'
                    width={["full", "auto"]}
                    height={EnlargeWidgetSize(260) + "px"}
                    timeWindow={true}
                    onTimeWindowChange={handleTimeWindowDailyDischargeChange}
                    onReset={DailyDischargeHandleReset}
                    fullScreen={true}
                >
                    <ColumnChart height={EnlargeWidgetContent(260, 230)} apiData={DailyDischargeData || [{}]}/>
                </ChartLayout>
            </GridItem>
            <GridItem >
                  <ChartLayout
                      title='Voltage & Hz'
                      width={["auto", "100%"]}
                      height={EnlargeWidgetSize(260) + "px"}
                      icon={FcLineChart}
                      timeWindow={true}
                      onTimeWindowChange={handleTimeWindowVoltChange}
                      onReset={VoltHandleReset}
                      fullScreen={true}
                  >
                      <LineChart height={EnlargeWidgetContent(260, 230)} apiData={VoltData || [{}]} />
                  </ChartLayout>
            </GridItem>
        </SimpleGrid>
        
        <SimpleGrid
            minChildWidth={["300px","500px"]}
            gap={1}
            mt={-3}
            maxW={"9xl"}
        >
            <GridItem w={"100%"}>
                <PlantViewTableLayout
                    title='PCS'
                    width={["full", "100%"]}
                    height={EnlargeWidgetSize(260) + "px"}
                    fullscreen={true}
                >
                    <PlantTable
                        paginationLimitProps={5}
                        column={PCSColumn}
                        apiData={PCSTableData || []}
                        autoWidth={false}
                        width='100%'
                        customColumn={true}
                        fullScreen={false}
                    />
                </PlantViewTableLayout>
            </GridItem>
              <GridItem>
                  <ChartLayout
                      title='Current'
                      width={["auto", "100%"]}
                      height={EnlargeWidgetSize(260) + "px"}
                      icon={FcLineChart}
                      timeWindow={true}
                      onTimeWindowChange={handleTimeWindowGridCurrentChange}
                      onReset={GridCurrentHandleReset}
                      fullScreen={true}
                  >
                      <LineChart height={EnlargeWidgetContent(260, 230)} apiData={GridCurrentData || [{}]} />
                  </ChartLayout>
              </GridItem>
        </SimpleGrid>
    </Box>
  )
}

export default BESS_KPIDashboard;
import { 
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Grid,
    GridItem,
    SimpleGrid,
    VStack
} from '@chakra-ui/react'
import { FaCaretRight, FaChartLine } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { BatteryBank, PCS, StateOfBattery, SubModuleOverview } from '../components/BESS/Overview'
import BatteryStatus from '../assets/BESS/Overview/BatteryStatus'
import ChartLayout from '../components/Layouts/ChartLayouts/ChartLayout'
import StackedColumnChart from '../components/widgets/charts/StackedColumnChart'
import { FaChartColumn } from 'react-icons/fa6'
import LineChart from '../components/widgets/charts/LineChart'
import UseBatteryStatus from '../Services/Hooks/Battery/UseBatteryStatus'
import { useEffect, useState } from 'react'
import { useTimeHandle } from '../Services/TimeWindowSetting'
import UseBESSDaily from '../Services/Hooks/Battery/UseBESSDaily'
import UseSomeTitle from '../Services/Hooks/Battery/UseSomeTitle'
import UseManyDeviceManyKeysChart from '../Services/Hooks/UseManyDeviceManyKeysChart'
import TopRibbon from '../components/TopRibbon'
import UseManyDeviceSameMultipleKeys from '../Services/Hooks/UseManyDeviceSameMultipleKeys'
import TimeDisplay from '../components/TimeDisplay'
import LandingTimeService from '../Services/LandingTimeService'
import { EnlargeWidgetContent, EnlargeWidgetSize, EnlargeWidgetSizeWidth } from '../Services/EnlargeWidget'

// *********************** Battery Status **********************

const BESS_OverviewDashboard = () => {

    // ************ Landing Time Process **********
    LandingTimeService();

    // var search = {
    //     devName : "PCS",
    //     keys : "ESS_SOC,ESS_Charge_Cap,ESS_Disharge_Cap,ESS_Disharge_Cap,ESS_Discharge_Rateii,ESS_Disharge_Cap,ESS_Disharge_Cap,ESS_Disharge_Cap,ESS_Discharge_Rate,ESS_Discharge_Rate"
    // }
    // const batteryStatus = UseBatteryStatus(search) || [];

    var searchHealth = {
        devName : "PCS",
        keys : "kWhdischarged_Day,ESS_SOH,ESS_Disharge_Cap,ESS_Disharge_Cap"
    }
    const batteryStatusHealth = UseBatteryStatus(searchHealth) || [];

    // ********************* BESS Output *********************
    const {
        timeWindow: timeWindowBESSOutput,
        handleTimeWindowChange: handleTimeWindowBESSOutputChange,
        handleReset: BESSOutputHandleReset
    } = useTimeHandle(5, "hour", "AVG", [5, "minute"]);

    
    var searchTagBESSOutput = { 
        devName : "PCS",
        keys: "AC_Active_Power_Watt,AC_Reactive_Power_var,Apperent",
        type : ["spline","spline", "spline"],
        name : ["kW", "kVar", "kVA"]
    };
    const BESSOutpuColor = [, ];
    const BESSOutputData = UseManyDeviceSameMultipleKeys(searchTagBESSOutput, timeWindowBESSOutput)?.map((series : any, index : number) => {
        if(series.name.includes("kW") ){
            return {
                ...series,
                color : "#3853A5"
            }
        } else if(series.name.includes("kVar")) {
            return {
                ...series,
                color : "#C92323"
            }
        } else if(series.name.includes("kVA")) {
            return {
                ...series,
                color : "#F4B725"
            }
         } else {
            return {
                ...series,
                color : "#CF4E4E"
            }
        }
    
    });
    // useEffect(() => {
    //     if (BESSOutputData) {
    //         console.log("BESSOutputData:", BESSOutputData);
    //     }
    // }, [BESSOutputData]);

    // ************************BESS Daily********************
    const {
        timeWindow: timeWindowBESSDaily,
        handleTimeWindowChange: handleTimeWindowBESSDailyChange,
        handleReset: BESSDailyHandleReset
    } = useTimeHandle(7, "day", "NONE", [5, "minute"]);
    
    var searchTagBESSDaily = [{ 
        devName : "PCS",
        keys: "kWhcharged_Day,kWhdischarged_Day",
        type : ["column", "column"],
        name : ["Daily Cahrge kWh", "Daily Discharge kWh"]
    }];
    const BESSDailyColor = ["#01875A", "#3853A5" , "#B11F24", "#7F5B9F", "#F4B725"]
    const BESSDailyData = UseManyDeviceManyKeysChart(searchTagBESSDaily, timeWindowBESSDaily, "LastValue")?.map((series : object, index : number) => ({
        ...series,
        color : BESSDailyColor[index]
    }));
    // useEffect(() => {
    //     if (BESSDailyData) {
    //         console.log("BESSDailyData:", BESSDailyData);
    //     }
    // }, [BESSDailyData]);

    // ****************** MIN MAX SOC*******************
    const {
        timeWindow: timeWindowMinMax,
        handleTimeWindowChange: handleTimeWindowMinMaxChange,
        handleReset: MinMaxHandleReset
    } = useTimeHandle(7, "day", "NONE", [5, "minute"]);

    var searchTagMinMax = { 
        devName : "PCS",
        keys: "ESS_SOC",
        type : ["spline"],
        name : ["SoC"]
    };
    const MinMaxColor = ["#3853A5", "#F4B725"];
    const MinMaxData = UseBESSDaily(searchTagMinMax, timeWindowMinMax)?.map((series : object, index : number) => ({
        ...series,
        color : MinMaxColor[index]
    }));
    // useEffect(() => {
    //     if (MinMaxData) {
    //         console.log("MinMaxData:", MinMaxData);
    //     }
    // }, [MinMaxData]);


    // ******************* Sub Module Overveiw  => previous == some title******************
    var searchTagSubModuleOverview = {
        devName : "PCS",
        sparkBarKeys : "ESS_SOC,ESS_DOD,ESS_SOH",
        keys : "ii,AC_Active_Power_Watt"
    }
    const [SubModuleOverviewData, setSubModuleOverviewData] =  useState([{bar : [0,0,0], temp : 0, volt : 0}])
    const fetchSubModuleOverviewData = UseSomeTitle(searchTagSubModuleOverview);
    useEffect(()=> {
        if(fetchSubModuleOverviewData) {
            setSubModuleOverviewData(fetchSubModuleOverviewData);
        }
    }, [fetchSubModuleOverviewData])

  return (
    <Box maxW="full" ml={10} px={{ base: 2, sm: 12, md: 17 }}>
        <Flex justify={"space-between"} >
            <Breadcrumb spacing="8px" separator={<FaCaretRight color="gray.500" />} mb={5}>
                <BreadcrumbItem color="rgba(0, 79, 134, 1)" fontSize={EnlargeWidgetSize(12)}>
                    <BreadcrumbLink>Home</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontSize={EnlargeWidgetSize(12)} as={Link} to="/portfolio">
                    Portfolio
                    </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontWeight={600} fontSize={EnlargeWidgetSize(12)}>
                    Sites
                    </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontWeight={600} fontSize={EnlargeWidgetSize(12)} as={Link}>
                    BESS
                    </BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>

            <TimeDisplay />
        </Flex>
            
            
{/* ****************** TOP RIBBON ************ */}

            <TopRibbon />

            <SimpleGrid
                w={"9xl"}
                m={0}
                minChildWidth={["320px", "400px"]}
                templateRows={"repeat(1, 1fr)"}
                templateColumns={["repeat(1, 1fr)","repeat(3, 1fr)"]}
                gap={1}
            >
                <GridItem w={"100%"}>
                    <VStack spacing={0}>
                            <StateOfBattery />
                        <GridItem mt={[3, 1]} ml={[-10, 0]} mr={[0, -3]} width={["320px", "full"]}>
                            <BatteryStatus
                                ACD = {batteryStatusHealth[0] || 0}
                                SOH = {batteryStatusHealth[1] || 0}
                                Min = {batteryStatusHealth[2] || 0}
                                Max = {batteryStatusHealth[3] || 0}
                                />
                        </GridItem>
                    </VStack>
                </GridItem>
                <GridItem w={["320px", "400px","660px","660px",EnlargeWidgetSizeWidth(660) + "px"]}>
                    <VStack w={"100%"} spacing={0}>
                        <ChartLayout
                            title='Daily BESS Discharge mWh'
                            width={["100%", "100%"]}
                            height={EnlargeWidgetSize(350) + "px"}
                            icon={FaChartColumn}
                            timeWindow = {true}
                            onTimeWindowChange={handleTimeWindowBESSDailyChange}
                            onReset={BESSDailyHandleReset}
                            fullScreen={true}
                        >
                            <StackedColumnChart height={EnlargeWidgetContent(350, 320)} apiData={BESSDailyData || [{}]} 
                                props={{yAxis : {title : {text : "kWh"}}}}
                            />
                        </ChartLayout>
                        <Box mt={-3} w={"100%"}>
                            <ChartLayout
                                title='BESS Output'
                                width={["100%", "100%"]}
                                height={EnlargeWidgetSize(350) + "px"}
                                icon={FaChartColumn}
                                timeWindow = {true}
                                onTimeWindowChange={handleTimeWindowBESSOutputChange}
                                onReset={BESSOutputHandleReset}
                                fullScreen={true}
                                >
                                <LineChart height={EnlargeWidgetContent(350, 320)} apiData = {BESSOutputData || [{}]} 
                                    props={{yAxis : {title : {text : "kW / kVAR"}}}}
                                />
                            </ChartLayout>
                        </Box>
                        <Box mt={-3} w={"100%"}>
                            <ChartLayout
                                title='SoC Characteristics' // previous => Min mAx Soc
                                width={["100%", "100%"]}
                                height={EnlargeWidgetSize(340) + "px"}
                                icon={FaChartLine}
                                timeWindow={true}
                                onTimeWindowChange={handleTimeWindowMinMaxChange}
                                onReset={MinMaxHandleReset}
                                fullScreen={true}
                                >
                                <LineChart height={EnlargeWidgetContent(340, 310)} apiData={MinMaxData || [{}]}
                                    props={{yAxis : {title : {text : "%"}}}}
                                />
                            </ChartLayout>
                        </Box>
                    </VStack>
                </GridItem>
                <GridItem w={["320px","auto"]}>
                    <VStack w={["320px","auto"]} spacing={0}>
                        <Box width={"100%"}>
                            <PCS />
                        </Box>
                        <Box width={"100%"} mt={-3}>
                            <SubModuleOverview apiData={SubModuleOverviewData || [{bar : [0,0,0], temp : 0, volt : 0}]} />
                        </Box>
                        <Box width={"100%"} mt={-3}>
                            <BatteryBank />
                        </Box>
                    </VStack>
                </GridItem>
            </SimpleGrid>
    </Box>
  )
}

export default BESS_OverviewDashboard
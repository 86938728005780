import { useState, useEffect, useCallback, useRef } from 'react';
import SourceContributionAPI from '../../api/SourceContributionAPI';

const UseSourceContribution = (textSearch: string, key: string, title : string[], resolution : string) => {
    const [data, setData] = useState([]);
    const hasFetchedRef = useRef(false);

    const fetchTelemetryData = useCallback(async () => {
        try {
            const response = await SourceContributionAPI(textSearch, key, title, resolution);
            setData(response?.data);
        } catch (error) {
            console.error('Error fetching telemetry data:', error);
        }
    }, []);

    useEffect(() => {
        // if (!hasFetchedRef.current) {
            fetchTelemetryData();
        //     hasFetchedRef.current = true;
        // }
        
        const intervalId = setInterval(() => {
            const currentIntervalTime = new Date().getMinutes();
            const latestStoredTime = parseInt(localStorage.getItem("landingTime") || "0");
            
            if((currentIntervalTime == new Date(latestStoredTime - 300000).getMinutes()) || (currentIntervalTime == new Date(latestStoredTime).getMinutes())) {
                
                fetchTelemetryData();
            }
      
          }, 60000);
      
          // Cleanup interval on component unmount
          return () => {
              
              clearInterval(intervalId)
          };
    }, [fetchTelemetryData]);

    return data;
};

export default UseSourceContribution;

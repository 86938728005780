import { 
    Box,
    Flex,
    Heading,
    HStack,
    Text,
    Tooltip,
    VStack,
} from "@chakra-ui/react";
import UseHeatmap from "../../Services/Hooks/PlantView/UseHeatmap";
import { EnlargeWidgetSize, EnlargeWidgetSizeWidth } from "../../Services/EnlargeWidget";

interface countType {
    label : string;
    name : string;
    value : number;
    capacity : number;
    color : string;
}


const Heatmap = () => {
    // const count = [
    //   {
    //     name : "one",
    //     value : 6787,
    //     color : "#132a13"
    //   },
    //   {
    //     name : "two",
    //     value : 677,
    //     color : "#4f772d"
    //   },
    //   {
    //     name : "three",
    //     value : 678,
    //     color : "#31572c"
    //   },
    //   {
    //     name : "four",
    //     value : 787,
    //     color : "#90a955"
    //   },  
    //   {
    //     name : "five",
    //     value : 87,
    //     color : "#ecf39e"
    //   },
    // ];
    const legends = [
        {
            name : "Running",
            color : "green"
        },
        {
            name : "Stop",
            color : "#f2a65a"
        },
        {
            name : "Trip",
            color : "red"
        },
        {
            name : "Non-Communicating",
            color : "#777"
        },
    ];
    var searchTag = {
        devName : "Inverter",
        power : "AC_Active_Power_Watt"
    }
    var apiName = "inverter"
    // const HeatmapData = UseHeatmap(apiName, searchTag) || [];

    var searchTagStatus = {
        devName : "Inverter",
        power :  "AC_Active_Power_Watt",
        trip : "Trip_Mode_Inv",
        // capacity : "Capacity"    // This is Directly added in API at Node Scrpit!!
    }
    var apiNameStatus = "status";
    const HeatmapStatusData = UseHeatmap(apiNameStatus, searchTagStatus) || [];

    var searchTagDGStatus = {
        devName : "DG",
        power : "AC_Active_Power_Watt",
        trip : "Trip_Mode_Inv",
        // capacity : "Capacity"    // This is Directly added in API at Node Scrpit!!
    }
    const HeatmapDGStatus = UseHeatmap(apiNameStatus, searchTagDGStatus) || [];

    return (
            <Box fontFamily={"inter"} pos={"relative"}height={"100%"} overflow={"auto"}>
                <Flex justify={"space-between"} direction={"column"} height={"100%"}>
                    <VStack align={"flex-start"} spacing={3}>
                        <VStack align={"flex-start"} spacing={1} mt={5}>
                            <Heading fontSize={EnlargeWidgetSize(12) + "px"}>Inverter</Heading>
                            <Flex
                            flexDirection={"row"}
                            p={1}
                            wrap={"wrap"}
                            gap={1}
                            >
                                {HeatmapStatusData.length > 0 && HeatmapStatusData.map((value : countType, index : number) => (
                                    <Tooltip label={`${value.label}: ${value.value} kW`} placement="top">
                                        <Flex direction={"column"}>
                                            <Box 
                                                height={EnlargeWidgetSize(30) + "px"}
                                                width={EnlargeWidgetSizeWidth(30) + "px"}
                                                bgColor={`${value.color}`}
                                                borderRadius={"20%"}
                                                // border={"1px solid blue"}
                                            ></Box>
                                            <Text color={"#777"} fontSize={EnlargeWidgetSize(8)}>{value.capacity ? (value.capacity + " kW") : ""}</Text>
                                        </Flex>
                                    </Tooltip>
                                ))}
                            </Flex>
                        </VStack>
                        <VStack align={"flex-start"} spacing={1} mt={0}>
                            <Heading fontSize={EnlargeWidgetSize(12) + "px"}>DG</Heading>
                            <Flex
                            flexDirection={"row"}
                            p={1}
                            wrap={"wrap"}
                            gap={1}
                            >
                                {HeatmapDGStatus.length > 0 && HeatmapDGStatus.map((value : countType, index : number) => (
                                    <Tooltip label={`${value.label}: ${value.value} kW` } placement="top">
                                        <Flex direction={"column"}>
                                            <Box 
                                                height={EnlargeWidgetSize(30) + "px"}
                                                width={EnlargeWidgetSizeWidth(30) + "px"}
                                                bgColor={`${value.color}`}
                                                borderRadius={"20%"}
                                                // border={"1px solid blue"}
                                            ></Box>
                                            <Text color={"#777"} fontSize={EnlargeWidgetSize(9)}>{value.capacity ? (value.capacity + " kW") : ""}</Text>
                                        </Flex>
                                    </Tooltip>
                                ))}
                            </Flex>
                        </VStack>
                        {/* <VStack align={"flex-start"} spacing={1} mt={5}>
                            <Heading size={"sm"}>Battery</Heading>
                            <Flex
                            flexDirection={"row"}
                            p={1}
                            wrap={"wrap"}
                            gap={1}
                            >
                                {HeatmapStatusData.length > 0 && HeatmapStatusData.map((value : countType, index : number) => (
                                    <Tooltip label={`Im Toolitp of ${index} ${value.name} ${value.value}`} placement="top">
                                        <Box 
                                            height={"45px"}
                                            width={"45px"}
                                            bgColor={`${value.color}`}
                                            borderRadius={"20%"}
                                            // border={"1px solid blue"}
                                        ></Box>
                                    </Tooltip>
                                ))}
                            </Flex>
                        </VStack>
                        <VStack align={"flex-start"} spacing={1} mt={5}>
                            <Heading size={"sm"}>String</Heading>
                            <Flex
                            flexDirection={"row"}
                            p={1}
                            wrap={"wrap"}
                            gap={1}
                            >
                                {HeatmapStatusData.length > 0 && HeatmapStatusData.map((value : countType, index : number) => (
                                    <Tooltip label={`Im Toolitp of ${index} ${value.name} ${value.value}`} placement="top">
                                        <Box 
                                            height={"45px"}
                                            width={"45px"}
                                            bgColor={`${value.color}`}
                                            borderRadius={"20%"}
                                            // border={"1px solid blue"}
                                        ></Box>
                                    </Tooltip>
                                ))}
                            </Flex>
                        </VStack> */}
                    </VStack>  
                    <HStack spacing={5}>
                        {legends.map((value, index) => (
                            <HStack>
                                <Box width={EnlargeWidgetSizeWidth(10) + "px"} height={EnlargeWidgetSize(10) + "px"} borderRadius={"25%"} bgColor={value.color}></Box>
                                <Text fontSize={EnlargeWidgetSize(12)} fontWeight={300} letterSpacing={1}>{value.name}</Text>
                            </HStack>
                        ))}
                    </HStack>
                </Flex>
            </Box>
    );
};

export default Heatmap;
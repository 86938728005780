import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsGantt from 'highcharts/modules/gantt';
import HighchartsReact from 'highcharts-react-official';

HighchartsGantt(Highcharts); // Initialize Gantt module

interface dataType {
    name : string;
    data : object[];
}

interface ganttChartType {
    height? : number;
    apiData? : dataType[] | [];
    props? : any;
    isFullscreen? : boolean;
}

const GanttChart: React.FC <ganttChartType> = ({height=1000, apiData, props, isFullscreen=false}) => {
    const [chartOptions, setChartOptions] = useState({
        chart : {
            height : 200,
            backgroundColor : "transparent"
        },
        title: {
            text: '',
        },
        tooltip: {
            formatter: function (this : any) {
                
                // Adjust start and end times by adding 5 hours and 30 minutes
                const addTime =  0//1000*60*60*5; //5:30 Hr
                
                const start = Highcharts.dateFormat('%Y-%m-%d %H:%M', this.point.start);
                const end = Highcharts.dateFormat('%Y-%m-%d %H:%M', this.point.end);
                return `<b>${this.point.name}</b><br/>
                        Start: ${start}<br/>
                        End: ${end}`;
            }
        },
        xAxis: {
            type : "datetime",
            opposite : false,
            gridLineWidth: 0,
            tickmarkPlacement: 'of',
            tickLength: 0,
            dateTimeLabelFormats: {
                day: '%e. %b',
            },
            // tickPositions: [],
            // label : {

            //     formatter: function(this : any) {
            //             return Highcharts.dateFormat('%H:%M', this.value + (5.5 * 3600 * 1000)); // Add 5.5 hours (IST) to the timestamp
            //     }
            // }
            // min: Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0), // Start of the day
            // max: Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59)  // End of the day
        },
        yAxis: {
            uniqueNames: true,
        },
        series: [] as Object[],
        exporting : {
            enabled : false,
        },
        credits : {
            enabled : false
        }
    });
    // Adjust chart height based on full-screen state
    useEffect(() => {
        const newHeight = isFullscreen ? 800 : height;
        setChartOptions((prevOptions) => ({
            ...prevOptions,
            chart: {
                ...prevOptions.chart,
                height: newHeight
            }
        }));
        // if(apiData && apiData?.length > 0) {
        //     if (isFullscreen) {
        //         apiData = [{}]
        //     } else {
                
        //     }
        // }
    }, [isFullscreen, height]);

    useEffect(() => {
        if(apiData) {
            // console.log(apiData)
            setChartOptions((prevOptions) => ({
                ...prevOptions,
                series : !isFullscreen? [{name : apiData[0]?.name, data : apiData[0]?.data.slice(0, 8)}] : apiData,
                ...props
            }))
        }
    }, [apiData, props, isFullscreen]);

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={'ganttChart'}
                options={chartOptions}
            />
        </div>
    );
};

export default GanttChart;
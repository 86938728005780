import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useTimeHandle } from "../../../Services/TimeWindowSetting";
import UseBESSDaily from "../../../Services/Hooks/Battery/UseBESSDaily";
import ChartLayout from "./ChartLayout";
import LineChart from "../../widgets/charts/LineChart";

interface ChartOnModalType {
    isOpen: boolean;
    onClose: () => void;
    devName : string;
}

function keySelection(device : string) {
    const InverterKeys = "AC_Active_Power_Watt,AC_Reactive_VAr,AC_Apparant_Power_VA";
    const DGKeys = "AC_Active_Power_Watt,AMP_L1,AMP_L2,AMP_L3";
    const GridKeys = "Grid_Power_kW,Bus_B_voltage_L1_L2,Bus_B_voltage_L2_L3,Bus_B_voltage_L3_L1,Grid_AVG_Frequency";
    const PCSKeys = "AC_Active_Power_Watt,AC_Reactive_Power_var"
    switch (device) {
        case "inverter":
            return [InverterKeys, ["Active Power", "Reactive Power", "Apparent"]];
            break;
        case "grid":
            return [GridKeys, ["Grid Power", "Voltage L1 L2", "Voltage L2 L3", "Voltage L2 L1", "Frequency"]];
            break;
        case "dg":
            return [DGKeys, ["Active Power", "AMP L1", "AMP L2", "AMP L3"]];
            break;
            case "pcs":
                return [PCSKeys, ["Active Power", "Reactive Power"]];
                break;
    
        default:
            return [InverterKeys, ["Active Power", "Reactive Power", "Apparent"]];
            break;
    }
}

const ChartOnModal: React.FC<ChartOnModalType> = ({ isOpen, onClose, devName }) => {

    // ******************Modal Chart *******************
    const {
        timeWindow: timeWindowModalChart,
        handleTimeWindowChange: handleTimeWindowModalChartChange,
        handleReset: ModalChartHandleReset
    } = useTimeHandle(1, "cdsf", "NONE", [5, "minute"]);


    let details = keySelection(devName.split("-")[0].toLowerCase());
    
    var searchTagModalChart = {     
        devName : devName,
        keys: details[0],
        type : ["spline", "spline", "spline", "spline"],
        name : details[1],
        marker : false
    };
    // const ModalChartColor = ["#3853A5", "#F4B725"];
    const ModalChartData = UseBESSDaily(searchTagModalChart, timeWindowModalChart);

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose} size={"6xl"}>
            <ModalOverlay
                bg='blackAlpha.300'
                backdropFilter='blur(10px) hue-rotate(90deg)'
            />
            <ModalContent>
                <ModalHeader>{devName} Chart</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <ChartLayout
                        title=""
                        width={["full", "full"]}
                        height={"auto"}
                        timeWindow={false}
                        onTimeWindowChange={handleTimeWindowModalChartChange}
                        onReset={ModalChartHandleReset}
                        fullScreen={false}
                        bg="transparent"
                    >
                        <LineChart height={300} apiData={ModalChartData || []} />
                    </ChartLayout>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ChartOnModal;

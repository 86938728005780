import { 
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Grid,
    GridItem,
    HStack,
    VStack,
    Text,
    SimpleGrid,
    Flex
} from '@chakra-ui/react'
import { FaCaretRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import ChartLayout from '../components/Layouts/ChartLayouts/ChartLayout'
import LineChart from '../components/widgets/charts/LineChart'
import DonutPieChart from '../components/widgets/charts/DonutPieChart'
import { PiChartDonutFill } from 'react-icons/pi'
import UseAssetSummary from '../Services/Hooks/UseAssetSummary'
import ColumnChart from '../components/widgets/charts/ColumnChart'
import { FaChartColumn } from 'react-icons/fa6'
import PlantViewTableLayout from '../components/Layouts/TableLayouts/PlantViewTableLayout'
import PlantTable from '../components/widgets/tables/PlantTable'
import PlantViewRuntime from '../assets/PlantView/PlantViewRuntime'
import GeneratorPowerDG from '../assets/GeneratorPowerDG'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import UseGeneratorTable from '../Services/Hooks/UseGeneratorTable'
import { useTimeHandle } from '../Services/TimeWindowSetting'
import UseBatteryStatus from '../Services/Hooks/Battery/UseBatteryStatus'
import UseBESSDaily from '../Services/Hooks/Battery/UseBESSDaily'
import UseManyDeviceSameKeyChart from '../Services/Hooks/UseManyDeviceSameKeyChart'
import UsePlanViewTable from '../Services/Hooks/PlantView/UsePlantViewTable'
import StripsPieChart from '../components/widgets/charts/StripsPieChart'
import UseManyDeviceManyKeysChart from '../Services/Hooks/UseManyDeviceManyKeysChart'
import { html } from 'gridjs'
import TopRibbon from '../components/TopRibbon'
import UseLatestValueOfSameKeyMultipleDevice from '../Services/Hooks/UseLatestValueOfSameKeyMultipleDevice'
import TimeDisplay from '../components/TimeDisplay'
import LandingTimeService from '../Services/LandingTimeService'
import { EnlargeWidgetContent, EnlargeWidgetSize, EnlargeWidgetSizeWidth } from '../Services/EnlargeWidget'


interface APIData {
    column : string[];
    dataFromAPI : string[][]; 
  }


const DGDashboard = () => {

    // ************ Landing Time Process **********
    LandingTimeService();


    // ************************* GENERATOR POWER *********************

    var search = {
        devName : "calculation",// Generally some of power of DG is Shown
        keys : "DG_Total_Power"
    }
    const GeneratorPower = UseBatteryStatus(search) || [0];

    // **********************Generator Table Previous (Date & Time First Row) *********************

    // const {
    //     timeWindow : timeWindowGeneratorTable,
    //     handleTimeWindowChange : handleTimeWindowGeneratorTableChange,
    //     handleReset : handleGeneratorTableReset
    // } = useTimeHandle(5, "minute", "NONE", [5, "minute"]);

    // const searchTag = { DG: "AC_Active_Power_Watt" };
    
    // const GeneratorTableData = UseGeneratorTable(searchTag, timeWindowGeneratorTable); 

    // ******************** Generator Table ***********************
    
    var searchGeneratorTable = {
        DG : "AC_Active_Power_Watt,Energy_Daily_kWh,AC_Reactive_Power_var,AMP_L1,AMP_L2,AMP_L3,Frequency_Hz_L1,Frequency_Hz_L2,Frequency_Hz_L3,Bus_B_voltage_L1_L2,Bus_B_voltage_L2_L3,Bus_B_voltage_L3_L1,Fuel_Rate,Engine_Speed_RPM"
    }
    var GeneratorColumn = [{name : "Name", formatter : (cell : any) => html(`<b"><center>${cell}</center></b>`)},"Power kW","Daily Export kWh","Power kVAR","L1 Amps","L2 Amps","L3 Amps","L1 Hz", "L2 Hz", "L3 Hz","L1-L2 Volts","L2-L3 Volts","L3-L1 Volts","Fuel Rate","Engine Speed"]
    const GeneratorTableData = UsePlanViewTable(searchGeneratorTable) as any;
    // console.log("GeneratorTableData", GeneratorTableData);


    // ********************* Generator Daily Energy *********************
    const {
        timeWindow: timeWindowGeneratorDailyEnergy,
        handleTimeWindowChange: handleTimeWindowGeneratorDailyEnergyChange,
        handleReset: GeneratorDailyEnergyHandleReset
    } = useTimeHandle(1, "cdsf", "AVG", [5, "minute"]);

    
    var searchTagGeneratorDailyEnergy = { 
        devName : "Calculation", 
        keys : "DG_Total_Energy",
        type : ["spline"],
        name : ["Generator Daily Energy"]
    };
    const GeneratorDailyEnergyData = UseBESSDaily(searchTagGeneratorDailyEnergy, timeWindowGeneratorDailyEnergy);
    // useEffect(() => {
    //     if (GeneratorDailyEnergyData) {
    //         console.log("GeneratorDailyEnergyData:", GeneratorDailyEnergyData);
    //     }
    // }, [GeneratorDailyEnergyData]);

    // ************************* Load Power *****************
    
    
    const searchObj = {
        devName : "calculation",
        keys : "SL_DG_Load_Percent_kW,SL_Grid_Percent_kW,SL_INV_Load_Percent_kW"
    };
    const PieName = ["Generator % load power","Grid % load power","SL_INV_Load_Percent_kW"]
    
    const pieData = UseBatteryStatus(searchObj) || [0,0,0];
    // console.log(pieData)
    
    // ********************* DG Power *********************
    const {
        timeWindow: timeWindowDGPower,
        handleTimeWindowChange: handleTimeWindowDGPowerChange,
        handleReset: DGPowerHandleReset
    } = useTimeHandle(10, "hour", "AVG", [5, "minute"]);
    
    
    var searchTagDGPower = { 
        devName : "DG", // cal
        keys : "AC_Active_Power_Watt",
        type : "spline",
        name : "DG-Power-"
    };
    const DGPowerData = UseManyDeviceSameKeyChart(searchTagDGPower, timeWindowDGPower);
    // useEffect(() => {
        //     if (DGPowerData) {
            //         console.log("DGPowerData:", DGPowerData);
            //     }
            // }, [DGPowerData]);
            
            // ********************* DG Ampere *********************
            const {
                timeWindow: timeWindowDGAmpere,
                handleTimeWindowChange: handleTimeWindowDGAmpereChange,
                handleReset: DGAmpereHandleReset
            } = useTimeHandle(10, "hour", "AVG", [5, "minute"]);
            
            
            var searchTagDGAmpere = { 
                devName : "DG", // cal
                keys : "AMP_L1", // This widget tag will be present in Cal
                type : "spline",
                name : "DG-Ampere-L"
            };
            const DGAmpereData = UseManyDeviceSameKeyChart(searchTagDGAmpere, timeWindowDGAmpere);
            // useEffect(() => {
                //     if (DGAmpereData) {
                    //         console.log("DGAmpereData:", DGAmpereData);
                    //     }
                    // }, [DGAmpereData]);
                    
                    // ********************* Fuel Consumption *********************
    const {
        timeWindow: timeWindowFuelConsumtion,
        handleTimeWindowChange: handleTimeWindowFuelConsumtionChange,
        handleReset: FuelConsumtionHandleReset
    } = useTimeHandle(1, "cdsf", "MIN", [1, "day"]);
    
    
    var searchTagFuelConsumtion = [{ 
        devName : "Durtek_DG", 
        keys : "DG_DieselUsed",   //AGC_EXPORT_DAY Not Present In BP720-DG
        type : ["column"],
        name : " Fuel Consumed (kWh) "
    },
    {
        devName : "Calculation", 
        keys : "DG_Total_Energy",   //AGC_EXPORT_DAY Not Present In BP720-DG
        type : ["column"],
        name : " Daily (kWh) "
    }];
    const FuelConsumtionColor = ["#A068E6", "#03BB7D", "#1284C0"] // 3 keys
    const FuelConsumtionData = UseManyDeviceManyKeysChart(searchTagFuelConsumtion, timeWindowFuelConsumtion)?.map((series : object, index : number) => ({
        ...series,
        color : FuelConsumtionColor[index]
    }));
    // useEffect(() => {
        //     if (FuelConsumtionData) {
            //         console.log("FuelConsumtionData:", FuelConsumtionData);
            //     }
            // }, [FuelConsumtionData]);
            
            
            // *********************** DG Runtime & Instance *****************
            // const runtimeWidget = [0,2,3,3,45,7];
            
            const searchDGRuntimeInstanceData = [{DG : "DG_Runtime"}, {DG : "DG_Runtime_Instance"}];
            const DGRuntimeInstanceData = UseLatestValueOfSameKeyMultipleDevice(searchDGRuntimeInstanceData) as any;
            // const runtime = DGRuntimeInstanceData? DGRuntimeInstanceData[0] : ;
            // const totalRuntime = runtime? runtime.reduce((prev : number, current : number) => prev+current) : undefined;
            // const instance = DGRuntimeInstanceData[1];
            // const totalInstance = instance.reduce((prev, current) => prev+current);
            
            // ************** RUNTIME *****************
            
            function runtimeFormat(runtime: number): string {
                // Get the total number of hours
                const hour = Math.floor(runtime / 60);
                
                // Get the remaining minutes
                const min = Math.round(runtime % 60);
            
                // Return formatted result
                return `${hour}h ${min}m`;
            }
            
            return (
                <Box maxW="full" ml={10} px={{ base: 2, sm: 12, md: 17 }}>
                    <Flex justify={"space-between"} >
                        <Breadcrumb spacing="8px" separator={<FaCaretRight color="gray.500" />} mb={5}>
                            <BreadcrumbItem color="rgba(0, 79, 134, 1)" fontSize={EnlargeWidgetSize(12)}>
                                <BreadcrumbLink>Home</BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontSize={EnlargeWidgetSize(12)} as={Link} to="/portfolio">
                                Portfolio
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontWeight={600} fontSize={EnlargeWidgetSize(12)}>
                                Sites
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontWeight={600} fontSize={EnlargeWidgetSize(12)} as={Link} to="/dg">
                                DG
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                        </Breadcrumb>

                        <TimeDisplay />
                    </Flex>

            {/* ****************** TOP RIBBON ************ */}
            <TopRibbon />

            {/* <SimpleGrid
                h={["100%", "min-content"]}
                minW={["full", "9xl"]}
                minChildWidth={["280px","400px"]}
                column={3}
                row={3}
                gap={1}
            > */}

            <SimpleGrid
                minW={["full", "9xl"]}
                minChildWidth={["280px","400px"]}
                column={3}
                row={3}
                gap={1}
            >
                <GridItem h={["215px", "215px", "215px", "215px", EnlargeWidgetSize(215) + "px"]} colSpan={1}>
                    <ChartLayout
                        width={["100%", "470px", "470px", "100%"]}
                        height={["100%" ,'100%']}
                        px='0'
                    >
                        <GeneratorPowerDG
                            value={(GeneratorPower[0]) || 0}
                        />
                </ChartLayout>
                </GridItem>
                <GridItem h={["215px", "215px", "215px", "215px", EnlargeWidgetSize(215) + "px"]} colSpan={1}>
                    <ChartLayout
                        title='Generator Daily Energy'
                        width={["full", "100%"]}
                        height={'100%'}
                        timeWindow={false}
                        onTimeWindowChange={handleTimeWindowGeneratorDailyEnergyChange}
                        onReset={GeneratorDailyEnergyHandleReset}
                        fullScreen={true}
                    >
                        <LineChart height={EnlargeWidgetContent(215, 188)}
                            props={{
                                xAxis : 
                                    {visible : false},
                                    legend : {enabled : false}, 
                                    exporting : {enabled : false}
                            }}
                            apiData={GeneratorDailyEnergyData || [{}]}
                        
                        />
                    </ChartLayout>
                </GridItem>
                <GridItem h={["215px", "215px", "215px", "215px", EnlargeWidgetSize(215) + "px"]} colSpan={1}>
                    <ChartLayout
                        title='% Load Power'
                        width={["full", "100%"]}
                        height={'100%'}
                        icon={PiChartDonutFill}
                    >
                        <StripsPieChart height={EnlargeWidgetContent(215, 200)} names={PieName} apiData={pieData} />
                    </ChartLayout>
                </GridItem>
            </SimpleGrid>
            <SimpleGrid
                h={["100%", "min-content"]}
                minW={["full", "9xl"]}
                minChildWidth={["280px","400px"]}
                column={3}
                row={3}
                gap={1}
                mt={2}
            >
                <GridItem h={["285px", "285px", "285px", "285px", EnlargeWidgetSize(285) + "px"]} colSpan={1}>
                    <ChartLayout
                        title='DG Power'
                        width={["full", "100%"]}
                        height='100%'
                        timeWindow={true}
                        onTimeWindowChange={handleTimeWindowDGPowerChange}
                        onReset={DGPowerHandleReset}
                        fullScreen={true}
                    >
                        <LineChart height={EnlargeWidgetContent(285, 250)} apiData={DGPowerData || [{}]}
                            props={{
                                yAxis : {
                                    title : {
                                        text : "Power kW"
                                    }
                                }
                            }}
                        />
                    </ChartLayout>
                </GridItem>
                <GridItem h={["285px", "285px", "285px", "285px", EnlargeWidgetSize(285) + "px"]} colSpan={1}>
                    <ChartLayout
                        title='DG Ampere'
                        width={["full", "100%"]}
                        height='100%'
                        timeWindow={true}
                        onTimeWindowChange={handleTimeWindowDGAmpereChange}
                        onReset={DGAmpereHandleReset}
                        fullScreen={true}
                    >
                        <LineChart height={EnlargeWidgetContent(285, 250)} apiData={DGAmpereData || [{}]}
                            props={{
                                yAxis : {
                                    title : {
                                        text : "Ampere"
                                    }
                                }
                            }}
                        />
                    </ChartLayout>
                </GridItem>
                <GridItem h={["285px", "285px", "285px", "285px", EnlargeWidgetSize(285) + "px"]} colSpan={1}>
                    <ChartLayout
                        title='Fuel Consumption'
                        width={["full", "100%"]}
                        height='100%'
                        icon={FaChartColumn}
                        timeWindow ={true}
                        onTimeWindowChange={handleTimeWindowFuelConsumtionChange}
                        onReset={FuelConsumtionHandleReset}
                        fullScreen={true}
                    >
                        <ColumnChart height={EnlargeWidgetContent(285, 250)} apiData={FuelConsumtionData || [{}]} />
                    </ChartLayout>
                </GridItem>
            </SimpleGrid>
            <SimpleGrid
                h={["100%", "min-content"]}
                minW={["full", "9xl"]}
                minChildWidth={["280px","400px"]}
                column={3}
                row={3}
                gap={1}
                mt={2}
            >
                <GridItem h={["285px", "285px", "285px", "285px", EnlargeWidgetSize(285) + "px"]} colSpan={[1, 2]}>
                    <PlantViewTableLayout
                        title='Generators'
                        width={["full", "100%"]}
                        height={EnlargeWidgetSize(271) + "px"}
                        fullscreen={true}
                        // timeWindow={true}
                        // onTimeWindowChange = {handleTimeWindowGeneratorTableChange}
                        // onReset={handleGeneratorTableReset}
                    >
                        <PlantTable
                            paginationLimitProps={4}
                            column={GeneratorColumn}
                            apiData={GeneratorTableData || [{ column: [], dataFromAPI: [] }]}
                            customColumn={true}
                            autoWidth={false}
                            width='100%'
                            fullScreen={false}
                        />
                    </PlantViewTableLayout>     
                </GridItem>
                <GridItem h={["285px", "285px", "285px", "285px", EnlargeWidgetSize(285) + "px"]} colSpan={1}>
                    <ChartLayout
                        title='DG Runtime'
                        initTimeTitle='Current Day'
                        width={["full", "485px", "485px", "485px", EnlargeWidgetSizeWidth(485) + "px"]}
                        height={EnlargeWidgetSize(271) + "px"}
                    >
                        <Box mt={4}>
                            <HStack spacing={EnlargeWidgetSize(10)} fontFamily={"inter"} fontSize={EnlargeWidgetSize(14)} color={"#4A4A4A"} mb={2}>
                                <VStack spacing={0}>
                                    <Text fontWeight={600}>Total Run Time</Text>
                                    {DGRuntimeInstanceData && <Text fontSize={EnlargeWidgetSize(26)} fontWeight={700}>{runtimeFormat(DGRuntimeInstanceData[0].reduce((prev : number, current : number) => prev + current))}</Text>}
                                </VStack>
                                <VStack spacing={0}>
                                    <Text fontWeight={600}>Total Run Instances</Text>
                                    {DGRuntimeInstanceData && <Text fontSize={EnlargeWidgetSize(26)} fontWeight={700}>{DGRuntimeInstanceData[1].reduce((prev : number, current : number) => prev+current)}</Text>}
                                </VStack>
                            </HStack>
                            <hr style={{borderColor: "#9B9A9A", width:"100%", margin:"auto"}} />
                            <HStack 
                                spacing={EnlargeWidgetSize(4)} 
                                overflowX={"auto"} 
                                minWidth={"100%"}mt={4}

                            >
                                {DGRuntimeInstanceData ? DGRuntimeInstanceData[0].map((value : number, index : number) => (
                                    <Box key={index} minWidth={"140px"}>
                                        <PlantViewRuntime 
                                            count={index+1}
                                            runtime={runtimeFormat(value)}
                                            instance={DGRuntimeInstanceData[1][index]}
                                        />
                                    </Box>
                                )) : <Box></Box>}
                            </HStack>
                        </Box>
                    </ChartLayout>
                </GridItem>
            </SimpleGrid>

            {/* </SimpleGrid> */}
    </Box>
  )
}

export default DGDashboard;
import { 
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Grid,
    GridItem,
    HStack,
    VStack,
    Text,
    SimpleGrid,
    Stack,
    Flex
} from '@chakra-ui/react'
import { FaCaretRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import ChartLayout from '../components/Layouts/ChartLayouts/ChartLayout'
import LineChart from '../components/widgets/charts/LineChart'
import DonutPieChart from '../components/widgets/charts/DonutPieChart'
import { PiChartDonutFill } from 'react-icons/pi'
import UseAssetSummary from '../Services/Hooks/UseAssetSummary'
import ColumnChart from '../components/widgets/charts/ColumnChart'
import { FaChartColumn } from 'react-icons/fa6'
import PlantViewTableLayout from '../components/Layouts/TableLayouts/PlantViewTableLayout'
import PlantTable from '../components/widgets/tables/PlantTable'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import UseGeneratorTable from '../Services/Hooks/UseGeneratorTable'
import { useTimeHandle } from '../Services/TimeWindowSetting'
import UseBatteryStatus from '../Services/Hooks/Battery/UseBatteryStatus'
import UseBESSDaily from '../Services/Hooks/Battery/UseBESSDaily'
import UseManyDeviceSameKeyChart from '../Services/Hooks/UseManyDeviceSameKeyChart'
import UsePlanViewTable from '../Services/Hooks/PlantView/UsePlantViewTable'
import { html } from 'gridjs'
import InverterPowerSVG from '../assets/InverterPowerSVG'
import UseInverterAvailability from '../Services/Hooks/UseInverterAvailability'
import Highcharts from 'highcharts';
import UseInverterStatus from '../Services/Hooks/UseInverterStatus'
import StripsPieChart from '../components/widgets/charts/StripsPieChart'
import GanttChart from '../components/widgets/charts/GanttChart'
import UseGanttChart from '../Services/Hooks/UseGanttChart'
import TopRibbon from '../components/TopRibbon'
import TabsTableLayout from '../components/Layouts/TableLayouts/TabsTableLayout'
import UseManyDeviceSameFilteredKeyTable from '../Services/Hooks/UseManyDeviceSameFilteredKeyTable'
import TimeDisplay from '../components/TimeDisplay'
import LandingTimeService from '../Services/LandingTimeService'
import { EnlargeWidgetContent, EnlargeWidgetSize } from '../Services/EnlargeWidget'


interface APIData {
    column : string[];
    dataFromAPI : string[][]; 
  }


const InverterDashboard = () => {


    // ************ Landing Time Process **********
    LandingTimeService();

    // ************************* Inverter POWER *********************

    var search = {
        devName : "Calculation", // sum for BP
        keys : "INV_Total_Power" //InverterPower
    }
    const InverterPower = UseBatteryStatus(search) || [0];




    
    // ********************* Inverter Daily Energy *********************
    const {
        timeWindow: timeWindowInverterDailyEnergy,
        handleTimeWindowChange: handleTimeWindowInverterDailyEnergyChange,
        handleReset: InverterDailyEnergyHandleReset
    } = useTimeHandle(1, "cdsf", "AVG", [5, "minute"]);
    
    
    var searchTagInverterDailyEnergy = { 
        devName : "calculation",                  //"Inverter-1", // cal
        keys : "PV_Daily_Energy_Today",         //"B1_Inverter_Inverter_1_Energy_Daily_kWh",
        type : ["spline"],
        name : ["Daily Energy"]
    };
    const InverterDailyEnergyData : any = UseBESSDaily(searchTagInverterDailyEnergy, timeWindowInverterDailyEnergy);
    // Use useState to manage the title state
    const [InverterDETitle, setInverterDETitle] = useState<number>(0);
    useEffect(() => {
        if (InverterDailyEnergyData && InverterDailyEnergyData.length > 0) {
            var len = InverterDailyEnergyData[0].data.length;
            var tempData = InverterDailyEnergyData[0].data;
            setInverterDETitle(tempData[len-1][1]);
            // console.log("InverterDETitle : ", InverterDETitle);
            // console.log("InverterDailyEnergyData:", InverterDailyEnergyData);
        }
    }, [InverterDailyEnergyData]);
    
    // ************************* Status ******************
    const {
        timeWindow: timeWindowInverterStatus,
        handleTimeWindowChange: handleTimeWindowInverterStatusChange,
        handleReset: InverterStatusHandleReset
    } = useTimeHandle(5, "minute", "NONE", [5, "minute"]);
    const searchObj = {
        devName : "Inverter",
        keys : "AC_Active_Power_Watt",
    };
    
    const StatusData = UseInverterStatus(searchObj, timeWindowInverterStatus) || [];
    const StatusLabel = ["Generating", "Non Generating", "Non Cummunication"]
    const pieData = StatusData.map((data, i) => ({
        name : StatusLabel[i],
        y : data
    }))
    
    // ********************* Inverter Power *********************
    const {
        timeWindow: timeWindowInverterPower,
        handleTimeWindowChange: handleTimeWindowInverterPowerChange,
        handleReset: InverterPowerHandleReset
    } = useTimeHandle(1, "cdsf", "AVG", [5, "minute"]);
    
    
    var searchTagInverterPower = { 
        devName : "Inverter", 
        keys : "AC_Active_Power_Watt",
        type : "spline",
        name : "INV-"
    };
    const InverterPowerColor = ["#C700BF", "#53971D", "#F4B725", "#09BCC7", "#1325CB", "#19CA16", '#9b20d9', '#9215ac', '#861ec9', '#7a17e6', '#7010f9', '#691af3',
            '#6225ed', '#5b30e7', '#533be1', '#4c46db', '#4551d5', '#3e5ccf',
            '#3667c9', '#2f72c3', '#277dbd', '#1f88b7', '#1693b1', '#0a9eaa',
            '#03c69b', '#00f194']
    const InverterPowerData = UseManyDeviceSameKeyChart(searchTagInverterPower, timeWindowInverterPower)?.map((series : object, index : number) => ({
        ...series,
        color : InverterPowerColor[index]
    }));
    // useEffect(() => {
    //     if (InverterPowerData) {
    //         console.log("InverterPowerData:", InverterPowerData);
    //     }
    // }, [InverterPowerData]);
    
    // ********************* Daily Energy kWh *********************
    const {
        timeWindow: timeWindowDailyEnergykWh,
        handleTimeWindowChange: handleTimeWindowDailyEnergykWhChange,
        handleReset: DailyEnergykWhHandleReset
    } = useTimeHandle(1.5, "hour", "AVG", [1, "hour"]);
    
    
    var searchTagDailyEnergykWh = { 
        devName : "Inverter", 
        keys : "Energy_Daily_kWh",
        type : "column",
        name : "INV - "
    };
    const DailyEnergykWhColor = ["#C92323", "#19CA16", "#0086CC", "#A38415", "#940386", "#8842E0", '#9b20d9', '#9215ac', '#861ec9', '#7a17e6', '#7010f9', '#691af3',
        '#6225ed', '#5b30e7', '#533be1', '#4c46db', '#4551d5', '#3e5ccf',
        '#3667c9', '#2f72c3', '#277dbd', '#1f88b7', '#1693b1', '#0a9eaa',
        '#03c69b', '#00f194']
    const DailyEnergykWhData = UseManyDeviceSameKeyChart(searchTagDailyEnergykWh, timeWindowDailyEnergykWh)?.map((series : object, index : number) => ({
        ...series,
        color : DailyEnergykWhColor[index]
    }));
    // useEffect(() => {
    //     if (DailyEnergykWhData) {
    //         console.log("DailyEnergykWhData:", DailyEnergykWhData);
    //     }
    // }, [DailyEnergykWhData]);

    // ********************** Inverter Availability *******************

    // *** THIS IS PREVIOUS AVAILABILITY FORMULA MADE IN NODE EXPRESS SERVER. IT WILL AUTOMATICALLY CALCULATE AVAILABILITY.
    /* const {
        timeWindow : timeWindowInverterAvail,
        handleTimeWindowChange : handleTimeWindowInverterAvailability,
        handleReset : InverterAvailabilityHandleRESET

    } = useTimeHandle(7, "day", "NONE", [5, "minute"]);

    var searchTagInverterAvail = {
        devName : "Inverter",
        keys : "AC_Active_Power_Watt",
        type : "spline",
        name : "INV -"
    }
    var POA = " B1_WMS_POA_W/m2";

    const InverterAvailData = UseInverterAvailability(searchTagInverterAvail, POA, timeWindowInverterAvail) 
    */
   
    // **** This is Last Value API for SAME KEY MANY DEVICE.
    const {
        timeWindow : timeWindowInverterAvail,
        handleTimeWindowChange : handleTimeWindowInverterAvailability,
        handleReset : InverterAvailabilityHandleRESET

    } = useTimeHandle(7, "day", "NONE", [5, "minute"]);

    var searchTagInverterAvail = {
        devName : "Inverter",
        keys : "Inverter_Availability",
        type : "spline",
        name : "INV -"
    }
    
    const InverterAvailData = UseManyDeviceSameKeyChart(searchTagInverterAvail,  timeWindowInverterAvail, true) ;

    // ********************* Inverter Operation Controll *********************
    const {
        timeWindow: timeWindowOperationControll,
        handleTimeWindowChange: handleTimeWindowOperationControllChange,
        handleReset: OperationControllHandleReset
    } = useTimeHandle(1, "cdsf", "AVG", [5, "minute"]);
    
    
    var searchTagOperationControll = { 
        devName : "Inverter", 
        keys : "AC_Active_Power_Watt",
        name : "INV-"
    };

    var OperationControllData = UseGanttChart(searchTagOperationControll, timeWindowOperationControll) || [];

    // ********************* Inverter Control *********************
    const {
        timeWindow: timeWindowInverterControl,
        handleTimeWindowChange: handleTimeWindowInverterControlChange,
        handleReset: InverterControlHandleReset
    } = useTimeHandle(1, "cdsf", "AVG", [5, "minute"]);
    
    
    var searchTagInverterControl = { 
        devName : "Inverter", // cal
        keys : "Active_Power_reference",
        type : "spline",
        name : "INV - "
    };
    const InverterControlColor = ["#C92323", "#19CA16", "#0086CC", "#A38415", "#940386", "#8842E0", '#9b20d9', '#9215ac', '#861ec9', '#7a17e6', '#7010f9', '#691af3',
        '#6225ed', '#5b30e7', '#533be1', '#4c46db', '#4551d5', '#3e5ccf',
        '#3667c9', '#2f72c3', '#277dbd', '#1f88b7', '#1693b1', '#0a9eaa',
        '#03c69b', '#00f194']
    const InverterControlData = UseManyDeviceSameKeyChart(searchTagInverterControl, timeWindowInverterControl)?.map((series : object, index : number) => ({
        ...series,
        color : InverterControlColor[index]
    }));
    // useEffect(() => {
    //     if (InverterControlData) {
    //         console.log("InverterControlData:", InverterControlData);
    //     }
    // }, [InverterControlData]);
    
    // ******************** Inverter Table ***********************
    
    var searchInverterTable = {
        Inverter : "Inverter_Communication,AC_Active_Power_Watt,AC_Reactive_Power_var,AC_Apparent_Power_VA,Active_Power_referance,Energy_Daily_kWh,Energy_Total_kWh,Frequency_Hz,Volt_L1_L2,Volt_L2_L3,Volt_L3_L1,Fault_Code"
    }
    var InverterColumn = [{name : "Name", formatter : (cell : any) => html(`<b><center>${cell}</center></b>`)}, {
        name : "State",
        formatter: (cell : any, row: any) => parseFloat(row.cells[2].data) > 0 ? html(`<div style="width:100%; display: flex; justify-content: center; align-items: center;"><div style="background:green; height:15px; width:15px; border-radius:50%;"></div></div>`) : html(`<div style="width:100%; display: flex; justify-content: center; align-items: center;"><div style="background:red; height:15px; width:15px; border-radius:50%;"></div></div>`)
        }, "Power kW", "Power kVAR", "Power KVA", "kW % Ref", "Daily Energy", "Total Energy", "Frequency Hz", "L1-L2 Volts", "L2-L3 Volts", "L3-L1 Volts", "Fault State"]

    const InverterTableData = UsePlanViewTable(searchInverterTable) as any;

    // ******************* MPPT Table ***********************
    
    var searchMPPTTable = {
        "Inverter" : "INV-String"
    };
    var searchFields = ["DC_String_Amps", "DC_String_Volt"];
    var FieldDisplayName = ["Volt", "Amps"];

    const MPPTTableData = UseManyDeviceSameFilteredKeyTable(searchMPPTTable, searchFields, FieldDisplayName) as any;
        
    // console.log("InverterTableData", InverterTableData);
    
        // For revisit after long time
    // useEffect(() => {
    //     const handleVisibilityChange = () => {
    //       if (document.visibilityState === 'visible') {
    //         // Trigger your data-fetching functions here
    //         InverterDailyEnergyHandleReset();
    //         InverterStatusHandleReset();
    //         InverterPowerHandleReset();
    //         DailyEnergykWhHandleReset();
    //         InverterAvailabilityHandleRESET();
    //         OperationControllHandleReset();
    //         InverterControlHandleReset();
    //         // Add any other resets or data-fetching functions
    //       }
    //     };
      
    //     document.addEventListener('visibilitychange', handleVisibilityChange);
      
    //     return () => {
    //       document.removeEventListener('visibilitychange', handleVisibilityChange);
    //     };
    //   }, []);

return (
    <Box maxW="full" ml={10} px={{ base: 2, sm: 12, md: 17 }}>
        <Flex justify={"space-between"} >
            <Breadcrumb spacing="8px" separator={<FaCaretRight color="gray.500" />} mb={5}>
                <BreadcrumbItem color="rgba(0, 79, 134, 1)" fontSize={EnlargeWidgetSize(12)}>
                    <BreadcrumbLink>Home</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontSize={EnlargeWidgetSize(12)} as={Link} to="/portfolio">
                        Portfolio
                    </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontWeight={600} fontSize={EnlargeWidgetSize(12)}>
                        Sites
                    </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontWeight={600} fontSize={EnlargeWidgetSize(12)} as={Link} to="/inverter">
                        Inverter
                    </BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>

            <TimeDisplay />
        </Flex>

            {/* ****************** TOP RIBBON ************ */}
                <TopRibbon />

                <GridItem maxW={["9xl"]} rowSpan={1} colSpan={1}>
                    <SimpleGrid
                        minChildWidth={['260px' ,'400px']} h={"max-content"} spacing={{ base: 3, lg: 3 }}
                        columns={3}
                        row={1}
                        gap={1}
                    >
                        <GridItem h={["215px", "215px", "215px", "215px", EnlargeWidgetSize(215) + "px"]} colSpan={1} rowSpan={1}>
                            <ChartLayout
                                width={["100%", "470px", "100%", "100%"]}
                                height={["100%" ,'100%']}
                                px='0'
                            >
                                <InverterPowerSVG
                                    value={(InverterPower[0]) || 0}
                                />
                            </ChartLayout>
                        </GridItem>
                        {/* ******** Inverter Daily Energy ********** */}
                        <GridItem h={["215px", "215px", "215px", "215px", EnlargeWidgetSize(215) + "px"]} colSpan={1} rowSpan={1}>
                            <ChartLayout
                                title='Inverter Daily Energy'
                                width={["full", "100%"]}
                                height='100%'
                                // timeWindow={true}
                                onTimeWindowChange={handleTimeWindowInverterDailyEnergyChange}
                                onReset={InverterDailyEnergyHandleReset}
                                fullScreen={false}
                            >
                                <LineChart
                                props={
                                        {title : 
                                            {text : (InverterDETitle).toFixed(2)+"kWh"
                                                ,style : {color : "#21A01E"}
                                            },
                                            xAxis : 
                                            {visible : false},
                                            legend : {enabled : true, align : "left"}, 
                                            exporting : {enabled : false},
                                            chart : {
                                                height : EnlargeWidgetContent(215, 200)
                                            }
                                        }
                                    }
                                apiData={InverterDailyEnergyData || [{}]}
                                />
                            </ChartLayout>
                        </GridItem>
                        <GridItem h={["min-content","215px", "215px", "215px", EnlargeWidgetSize(215) + "px"]} colSpan={1} rowSpan={1}>
                                <ChartLayout
                                    title='Status'
                                    width={["full", "100%"]}
                                    height='100%'
                                    icon={PiChartDonutFill}
                                >
                                    <Stack direction={["column", "row"]} spacing={1}>
                                        <StripsPieChart
                                            height={EnlargeWidgetContent(215, 180)}
                                            names={StatusLabel}
                                            apiData={StatusData}
                                            color={["#0086CC", "#3BB1A8", "#B6B0B0"]}
                                        />
                                        <VStack fontWeight={600} fontFamily={"inter"} fontSize={EnlargeWidgetSize(12)} align={"start"} textAlign={"left"}>
                                            <VStack spacing={0} alignItems={"flex-start"}>
                                                <Text>Generating</Text>
                                                <Text color={"#0086CC"} fontSize={EnlargeWidgetSize(14)}>{StatusData[0]}</Text>
                                            </VStack>
                                            <VStack spacing={0} alignItems={"flex-start"}>
                                                <Text>Non Generating</Text>
                                                <Text color={"#3BB1A8"} fontSize={EnlargeWidgetSize(14)}>{StatusData[1]}</Text>
                                            </VStack>
                                            <VStack spacing={0} alignItems={"flex-start"}>
                                                <Text>Non Communicating</Text>
                                                <Text color={"#B6B0B0"} fontSize={EnlargeWidgetSize(14)}>{StatusData[2]}</Text>
                                            </VStack>
                                            <VStack spacing={0} alignItems={"flex-start"}>
                                                <Text>Total</Text>
                                                <Text color={"#000"} fontSize={EnlargeWidgetSize(14)}>{StatusData[0] + StatusData[1] + StatusData[2]}</Text>
                                            </VStack>
                                        </VStack>
                                    </Stack>
                                </ChartLayout>
                        </GridItem>
                    </SimpleGrid>
                </GridItem>
                
                <GridItem maxW={"9xl"} rowSpan={1} colSpan={1}>
                    <SimpleGrid
                        mt={2}
                        minChildWidth={["260px","500px"]}
                        gap={[1]}
                    >
                        <GridItem h={["300px", "300px", "300px", "300px", EnlargeWidgetSize(300) + "px"]} colSpan={1} rowSpan={1}>
                            <ChartLayout
                                title='Inverter Power'
                                width= {["full", "auto"]} //{["100%", "100%", "500px", "450px", "710px", "100%"]}
                                height={["300px", "100%"]}
                                timeWindow={true}
                                onTimeWindowChange={handleTimeWindowInverterPowerChange}
                                onReset={InverterPowerHandleReset}
                                fullScreen={true}
                            >
                                <LineChart height={EnlargeWidgetContent(300, 270) + "px"}  apiData={InverterPowerData || [{}]}
                                    props={{
                                        yAxis : {
                                            title : {
                                                text : "kW",
                                                style : {
                                                    letterSpacing : "1px",
                                                    fontWeight : "500",
                                                    color : "#606060",
                                                }
                                            }
                                        },
                                        
                                    }}
                                />
                            </ChartLayout>
                        </GridItem>
                        <GridItem h={["300px", "300px", "300px", "300px", EnlargeWidgetSize(300) + "px"]} colSpan={1} rowSpan={1}>
                            <ChartLayout
                                title='Daily Energy kWh'
                                width= {["full", "auto"]} //{["100%", "100%", "500px", "450px", "710px", "100%"]}
                                height={["300px", "100%"]}
                                icon={FaChartColumn}
                                timeWindow ={true}
                                onTimeWindowChange={handleTimeWindowDailyEnergykWhChange}
                                onReset={DailyEnergykWhHandleReset}
                                fullScreen={true}
                            >
                                <ColumnChart height={EnlargeWidgetContent(300, 270) + "px"} apiData={DailyEnergykWhData || [{}]}
                                    props={{
                                        yAxis : {
                                            title : {
                                                text : "kWh",
                                                style : {
                                                    letterSpacing : "1px",
                                                    fontWeight : "500",
                                                    color : "#606060",
                                                }
                                            }
                                        }
                                    }}
                                />
                            </ChartLayout>
                        </GridItem>
                    </SimpleGrid>
                </GridItem>

                <GridItem maxW={"9xl"} rowSpan={1} colSpan={1}>
                    <SimpleGrid
                        mt={2}
                        minChildWidth={["260px","500px"]}
                        // h={300}
                        // templateRows={"repeat(1, 1fr)"}
                        // templateColumns={"repeat(2, 1fr)"}
                        gap={1}
                    >
                        <GridItem h={["300px", "300px", "300px", "300px", EnlargeWidgetSize(300) + "px"]} colSpan={1} rowSpan={1}>
                            <ChartLayout
                                title='Inverter Availability'
                                initTimeTitle='Last 7 Days'
                                width={["full", "100%"]}
                                height={["300px", "100%"]}
                                icon={FaChartColumn}
                                // timeWindow ={true}
                                onTimeWindowChange={handleTimeWindowInverterAvailability}
                                onReset={InverterAvailabilityHandleRESET}
                                fullScreen={true}
                            >
                                <ColumnChart height={EnlargeWidgetContent(300, 270) + "px"} apiData={InverterAvailData || [{}]}
                                    props={{
                                        xAxis : {
                                            labels : {
                                              enabled : true,
                                              formatter: function(this : any) {
                                                  return Highcharts.dateFormat('%b %d', this.value + (5.5 * 3600 * 1000)); // Add 5.5 hours (IST) to the timestamp
                                              }
                                            },
                                        },
                                        yAxis : {
                                            title : {
                                                text : "Availability %"
                                            }
                                        }
                                    }}
                                />
                            </ChartLayout>
                        </GridItem>
                        <GridItem h={["300px", "300px", "300px", "300px", EnlargeWidgetSize(300) + "px"]} colSpan={1} rowSpan={1}>
                            <ChartLayout
                                title='Operation Pattern'
                                initTimeTitle='Current Day'
                                width={["full", "100%"]}
                                height={["300px", "100%"]}
                                // timeWindow={true}
                                onTimeWindowChange={handleTimeWindowInverterControlChange}
                                onReset={InverterControlHandleReset}
                                fullScreen={true}
                            >
                                <GanttChart height={EnlargeWidgetContent(300, 270)} apiData={OperationControllData} />
                            </ChartLayout>
                        </GridItem>
                    </SimpleGrid>
                </GridItem>
                
                <GridItem maxW={"9xl"} rowSpan={1} colSpan={1}>    
                    <SimpleGrid
                        mt={2}
                        // h={300}
                        minChildWidth={["300px", "400px"]}
                        gap={1}
                        mb={3}
                    >
                        <GridItem h={[305, 305, 305, 305, EnlargeWidgetSize(305)]} colSpan={1} rowSpan={1} overflow={"auto"}>
                            <TabsTableLayout

                                tabs = {["INV", "MPPT"]}
                                dotColors={["yellow", "Blue"]}
                                title='Inerters'
                                width={["full", "100%"]}
                                height={EnlargeWidgetSize(305) + "px"}
                                fullscreen={true}
                                childrens={[
                                    <PlantTable 
                                        paginationLimitProps={7}
                                        column={InverterColumn}
                                        apiData={InverterTableData || []}
                                        fullScreen={false}
                                        autoWidth={false}
                                        width='100%'
                                        customColumn={true}
                                        />,
                                    <PlantTable
                                        paginationLimitProps={8}
                                        column={[{name : "Name", formatter : (cell : any) => html(`<center>${cell}</center>`)}, ...FieldDisplayName]}
                                        apiData={MPPTTableData}
                                        fullScreen={false}
                                        autoWidth={false}
                                        width='100%'
                                        customColumn={true}
                                    />
                                ]}
                            />
                            
                        </GridItem>
                        <GridItem h={[290, 290, 290, 290, EnlargeWidgetSize(290)]} colSpan={1} rowSpan={1}>
                            <ChartLayout
                                title='Inverter Control'
                                width={["full", "100%"]}
                                height='100%'
                                timeWindow={true}
                                onTimeWindowChange={handleTimeWindowInverterControlChange}
                                onReset={InverterControlHandleReset}
                                fullScreen={true}
                            >
                                <LineChart height={EnlargeWidgetContent(290, 240)} apiData={InverterControlData || [{}]}
                                    props={{
                                        yAxis : {
                                            title : {
                                                text : "% kW Ref",
                                                style : {
                                                    letterSpacing : "1px",
                                                    fontWeight : "500",
                                                    color : "#606060",
                                                }
                                            }
                                        },
                                    }}
                                />
                            </ChartLayout>
                        </GridItem>
                    </SimpleGrid>
                        {/* <Box>ZAKIR</Box> */}
                    {/* </TabsTableLayout> */}
                </GridItem>
            {/* </Grid> */}
    </Box>
  )
}

export default InverterDashboard;
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

interface TimeWindowProps {
    isOpen: boolean;
    onClose: () => void;
    // firstInterval : [number, string];
    // onFirstInit : (startTs: number, endTs: number, aggregate: string) => void;
    onSave: (from: string, to: string, aggregation: string, interval : number) => void;
    onReset : (reset : boolean) => void;
}

// Time Setting Options
const second = 1000;
const minute = 60 * second;
const hour = 60 * minute;
const day = 24 * hour;
const week = 7 * day;
const month = 30 * day;
const quarter = 3 * month;
const year = 4 * quarter;

export const TimeWindow: React.FC<TimeWindowProps> = ({ isOpen, onClose, onSave, onReset }) => {
    const OverlayOne = () => (
        <ModalOverlay
            bg="blackAlpha.300"
            backdropFilter="blur(10px) hue-rotate(90deg)"
        />
    );

    const [overlay] = useState(<OverlayOne />);

    const [fromTime, setFromTime] = useState<string>("");
    const [toTime, setToTime] = useState<string>("");
    const [aggregation, setAggregation] = useState<string>("NONE");
    const [interval, setInterval] = useState<number>(5*minute);
    const [validIntervals, setValidIntervals] = useState<number[]>([]);

    const allIntervals = [
        { label: "5 Minute", value: 5 * minute },
        { label: "10 Minute", value: 10 * minute },
        { label: "15 Minute", value: 15 * minute },
        { label: "30 Minute", value: 30 * minute },
        { label: "1 Hour", value: hour },
        { label: "2 Hour", value: 2 * hour },
        { label: "5 Hour", value: 5 * hour },
        { label: "10 Hour", value: 10 * hour },
        { label: "12 Hour", value: 12 * hour },
        { label: "1 Day", value: day },
        { label: "1 Week", value: 7 * day },
    ];

    useEffect(() => {
        const updateValidIntervals = () => {
            if (!fromTime || !toTime) return;

            const from = new Date(fromTime).getTime();
            const to = new Date(toTime).getTime();
            const difference = to - from;

            if (aggregation === "NONE") {
                setValidIntervals(allIntervals.map((interval) => interval.value));
            } else {
                const filteredIntervals = allIntervals
                    .filter((interval) => {
                        if (difference > 2 * day && interval.value === 5 * minute) {
                            return false; // Hide 5-minute option
                        }
                        if (difference > 3 * day && interval.value === 10 * minute) {
                            return false; // Hide 10 minute option
                        }
                        if (difference > 7 * day && interval.value === 15 * minute) {
                            return false; // Hide 15-minute option
                        }
                        if (difference > 7 * day && interval.value === 30 * minute) {
                            return false; // Hide 30-minute option
                        }
                        if (difference > 29 * day && interval.value === 1 * hour) {
                            return false; // Hide 1 hour option
                        }
                        if (difference > 1.5 * month && interval.value === 2 * hour) {
                            return false; // Hide 2-hour option
                        }
                        if (difference > 3 * month && interval.value === 5 * hour) {
                            return false; // Hide 5-hour option
                        }
                        if (difference > 5 * month && interval.value === 10 * hour) {
                            return false; // Hide 10-hour option
                        }
                        if (difference > 6 * month && interval.value === 12 * hour) {
                            return false; // Hide 12-hour option
                        }
                        if (difference > 8 * month && interval.value === 1 * day) {
                            return false; // Hide 1-day option
                        }
                        if (difference > 1 * year && interval.value === 1 * week) {
                            return false; // Hide 1-week option
                        }
                        return true;
                    })
                    .map((interval) => interval.value);
                setValidIntervals(filteredIntervals);
            }
        };

        updateValidIntervals();
    }, [fromTime, toTime, aggregation]);

    const handleSave = () => {
        onSave(fromTime, toTime, aggregation, interval);
        onClose();
    };

    const handleReset = () => {
        onReset(true)
        onClose();
    };

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} blockScrollOnMount={true}>
            {overlay}
            <ModalContent>
                <ModalHeader>Time Window</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl isRequired mb={2}>
                        <FormLabel>From</FormLabel>
                        <Input
                            type="datetime-local"
                            value={fromTime}
                            onChange={(e) => setFromTime(e.target.value)}
                        />
                    </FormControl>
                    <FormControl isRequired mb={2}>
                        <FormLabel>To</FormLabel>
                        <Input
                            type="datetime-local"
                            value={toTime}
                            onChange={(e) => setToTime(e.target.value)}
                        />
                    </FormControl>
                    <FormControl isRequired mb={2}>
                        <FormLabel>Aggregate</FormLabel>
                        <Select
                            value={aggregation}
                            onChange={(e) => setAggregation(e.target.value)}
                        >
                            <option value="MIN">Min</option>
                            <option value="MAX">Max</option>
                            <option value="SUM">SUM</option>
                            <option value="AVG">AVG</option>
                            {/* <option value="COUNT">COUNT</option> */}
                            <option value="NONE">NONE</option>
                        </Select>
                    </FormControl>
                    <FormControl isRequired mb={2}>
                        <FormLabel>Interval</FormLabel>
                        <Select
                            py={3}
                            value={interval}
                            onChange={(e) => setInterval(parseInt(e.target.value))}
                        >
                            {allIntervals.map(
                                (option) =>
                                    validIntervals.includes(option.value) && (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    )
                            )}
                        </Select>
                    </FormControl>
                </ModalBody>
                <ModalFooter display={"flex"} justifyContent={"space-between"}>
                    <Button colorScheme="red" mr={3} onClick={handleReset}>
                        Reset
                    </Button>
                    <Box>
                        <Button colorScheme="blue" mr={3} onClick={handleSave}>
                            Save
                        </Button>
                        <Button onClick={onClose}>Close</Button>
                    </Box>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

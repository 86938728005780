import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Image,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import swal from 'sweetalert';
import UseAuth from '../../Services/Hooks/UseAuth'
import { useNavigate } from 'react-router';
import CustomerHandlingService from '../../Services/CustomerHandlingService';
import { useCustomerOptionsContext } from '../../Context/CustomerOptionsContext';
import { useSelectedCustomerIDContext } from '../../Context/SelectedCustomerIDContext';
import LoginBG from '../../assets/LoginBG.svg';
import logo from '../../assets/Logo_Cogneta.svg';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import { EnlargeWidgetSize, EnlargeWidgetSizeWidth } from '../../Services/EnlargeWidget';

export default function Login() {

  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)
  
    const {login} = UseAuth();

    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    const navigate = useNavigate();
    const goToForgotPassword = () => {
      navigate('/forgotPassword')
    }

    const {setCustomerOptions} = useCustomerOptionsContext();
    const {setSelectedCustomerID} = useSelectedCustomerIDContext();

    const handleSubmit = async (event : any) => {
        event.preventDefault();
        const response = await login(username, password);
        if(response === false) {
          swal({
            title: "Authentication Failed!",
            text: "Invalid Username or Password!",
            icon: "error",
          });
        }else {
          try {
            await CustomerHandlingService(setCustomerOptions, setSelectedCustomerID);
            
          } catch (error) {
            console.error("Error fetching customer data:", error);
          }
          const requestedLocation = localStorage.getItem("requestedLocation") as string;
          const authority = localStorage.getItem("authority");
          if(requestedLocation == "/") {
            if(authority == "Customer Users") {
              navigate("/plantview");
            } else {
              navigate("/portfolio");
            }
          } else {
            navigate(requestedLocation);
          }
        }

    }

    return (
    <Flex
      minH={'100vh'}
      maxW={"9xl"}
      align={'center'}
      justify={'center'}
      bg={[useColorModeValue('gray.50', 'gray.800'),`url(${LoginBG}) no-repeat center/cover`]}
      >
      <Stack spacing={0} mx={'auto'} position={["relative","absolute"]} left={[0, 20]} width={'30%'} py={12} px={6}>
        <Stack align={'center'}>
          {/* <Heading fontSize={'4xl'}>Sign in to your account</Heading> */}
          <Image src={logo} width={"70%"} />
        </Stack>
        <Box
          rounded={'lg'}
          bg={["white" ,useColorModeValue('transparent', 'gray.700')]}
          // boxShadow={'lg'}
          p={8}>
            <Text fontSize={EnlargeWidgetSize(18) + "px"} lineHeight={EnlargeWidgetSize(19) + "px"} fontWeight={500} color={'#181818'} mb={5}>
              Please Enter Your Account details
            </Text>
            <form onSubmit={handleSubmit}>
                <Stack spacing={EnlargeWidgetSize(4)} letterSpacing={1}>
                    <FormControl id="email">
                    <FormLabel fontSize={EnlargeWidgetSize(16) + "px"} color={"#000"} fontWeight={300}>Username (email)</FormLabel>
                    <Input bg={"#FFFFFF"} h={EnlargeWidgetSize(30) + "px"} fontSize={EnlargeWidgetSize(16) + "px"} type="email" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </FormControl>
                      <FormControl id="password">
                      <FormLabel fontSize={EnlargeWidgetSize(16) + "px"} color={"#000"} fontWeight={300}>Password</FormLabel>
                    <InputGroup>
                      <Input bg={"#FFFFFF"} h={EnlargeWidgetSize(30) + "px"} fontSize={EnlargeWidgetSize(16) + "px"} type={show? "text" : "password"} value={password} onChange={(e) => setPassword(e.target.value)} />
                      <InputRightElement width={EnlargeWidgetSizeWidth(50) + "px"} mt={window.screen.width > 2000 ? EnlargeWidgetSize(7.5) : 0}>
                        <Button h={EnlargeWidgetSize(28) + "px"} w={"100%"} borderRadius={0} fontSize={EnlargeWidgetSize(19) + "px"} borderLeft={"1px solid #657079"} onClick={handleClick}>
                          {show ? <IoEye /> : <IoEyeOff />}
                        </Button>
                      </InputRightElement>            
                    </InputGroup>
                      </FormControl>
                    <Stack spacing={10}>
                    <Stack
                        direction={{ base: 'column', sm: 'row' }}
                        align={'start'}
                        justify={'space-between'}>
                        <Checkbox visibility={'hidden'}>Remember me</Checkbox>
                        <Text
                           color={'blue.400'}
                           cursor={'pointer'}
                           onClick={goToForgotPassword}
                           fontSize={EnlargeWidgetSize(16) + "px"}
                           >
                            Forgot password?
                        </Text>
                    </Stack>
                    <InputGroup>
                      <Button
                          bg={'#0DC39A'}
                          width={"100%"}
                          rounded={"lg"}
                          h={"auto"}
                          fontSize={EnlargeWidgetSize(22) + "px"}
                          fontWeight={700}
                          color={'white'}
                          type='submit'
                          _hover={{
                          bg: 'green.400',
                          }}>
                          Login
                      </Button>
                    </InputGroup>
                    </Stack>
                </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  )
}
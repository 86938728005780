import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  IconButton,
  Image,
  Icon,
  HStack,
  VStack,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  Text,
  useColorMode,
  FormControl,
  FormLabel,
  Tabs,
  TabList,
  Tab,
} from "@chakra-ui/react";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import { FaBell, FaSignOutAlt } from "react-icons/fa";
import { FaCircleUser } from "react-icons/fa6";
import { FiChevronDown } from "react-icons/fi";
import { jwtDecode } from "jwt-decode"; // Corrected import
import { useLocation, useNavigate } from "react-router";
import SidebarContent from "./SidebarContent";
import useAuth from "../../Services/Hooks/UseAuth";
import cogneta_logo from "../../assets/cogneta_logo.png";
import cogneta from "../../assets/cogneta.png";
import { Select } from "chakra-react-select";
import { useCustomerOptionsContext } from "../../Context/CustomerOptionsContext";
import { useSelectedCustomerIDContext } from "../../Context/SelectedCustomerIDContext";
import { Link } from "react-router-dom";
import { useBESSNavTabsContext } from "../../Context/BESSNavTabsContext";
import NotificationPopup from "./NotificationPopup";
import { PlantTimeZoneSelection } from "../../Services/CustomerHandlingService";

interface JwtHeader {
  firstName: string;
  lastName: string;
  scopes: string[];
}

const Navbar: React.FC<{ children: React.JSX.Element }> = ({ children }) => {
  const jwt = localStorage.getItem("token");
  if (!jwt) {
    throw new Error("JWT not found in localStorage");
  }

  let fname = "";
  let lname = "";
  let user = "";

  try {
    const decodedToken: JwtHeader = jwtDecode<JwtHeader>(jwt);
    const { firstName, lastName, scopes } = decodedToken;
    fname = firstName;
    lname = lastName;
    user = scopes[0];
  } catch (error) {
    console.error("Error decoding token:", error);
  }

  const navigate = useNavigate();
  const location = useLocation();
  const { colorMode, toggleColorMode } = useColorMode();
  const { logout } = useAuth();
  const [isSidebarExpanded, setSidebarExpanded] = useState(false);
  
  const handleLogOut = async () => {
    const response = await logout();
    if (response === true) {
      navigate("/");
    }
  };
  
  const handleToggleSidebar = () => {
    setSidebarExpanded(!isSidebarExpanded);
  };
  
  const { customerOptions } = useCustomerOptionsContext();
  const {selectedCustomerID, setSelectedCustomerID} = useSelectedCustomerIDContext();

  const handlePlantChange = (selectedOption: any) => {
    setSelectedCustomerID(selectedOption?.value);
    localStorage.setItem("SelectedCustomerId", selectedOption?.value || "");
      PlantTimeZoneSelection(selectedOption?.value);

    window.location.reload();
  };
  // console.log(customerOptions)
  // console.log(selectedCustomerID)
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(null);
  useEffect(() => {
    if (customerOptions) {
      // console.log(customerOptions)
      setSelectedCustomerId(selectedCustomerID);
      localStorage.setItem("SelectedCustomerId", selectedCustomerID || "");
      if(selectedCustomerID) {
        PlantTimeZoneSelection(selectedCustomerID);
      }
    } 
  }, []);

  const { BESSNavTabs } = useBESSNavTabsContext();

  // ******************* Mouse Child Event********
  const handleChildBox = () => {
    if(isSidebarExpanded) {
      setSidebarExpanded(false)
    }
  }
  return (
    <Box as="section" bg="#F2F3F8" _dark={{ bg: "gray.700" }} minH="100vh">
      <SidebarContent
        isSidebarExpanded={isSidebarExpanded}
        onToggleSidebar={handleToggleSidebar}
        display={{ base: "block", md: "block" }}
        boxShadow="rgba(0, 0, 0, 0.8)"
        bgColor="#001C3C"
      />
      <Flex
        as="header"
        align="center"
        justify="space-between"
        w="full"
        pos="fixed"
        top={0}
        left={0}
        px="4"
        pl="0"
        bg="white"
        _dark={{ bg: "gray.800" }}
        borderBottomWidth="1px"
        color="inherit"
        h={"55px"}
        boxShadow="rgba(0, 0, 0, 0.06)"
        zIndex={9999}
      >
        <Flex px={["2","4"]} py="5" align="center">
          <Image src={cogneta_logo} alt="logo" w={["11","30"]} h={["10", "20"]} display={["none", "block"]} />
          <Image src={cogneta} alt="logo" w={["10","30"]} h={["10", "20"]} mr={2} display={["block", "none"]} />
          <FormControl
            ml={[1, 5]}
            px={[1, 5]}
            width={[200, 300]}
            h={14}
            borderLeft={"1px solid #D1D8DD"}
            // display={["none", "block"]}
          >
            <FormLabel
              fontFamily={"inter"}
              fontSize={10}
              fontWeight={10}
              color={"#747474"}
              mt={2}
            >
              Select Plant
            </FormLabel>
            <Box mt={-3} fontWeight={600}>
              <Select
                isMulti={false}
                name="plants"
                placeholder="Select Any Plant"
                closeMenuOnSelect={true}
                variant="unstyle"
                focusBorderColor="transparent"
                options={customerOptions || []}
                value={customerOptions?.find(option => option.value === localStorage.getItem("SelectedCustomerId"))}
                onChange={handlePlantChange}
                useBasicStyles={true}
              />
            </Box>
          </FormControl>
          {/* For Battery - Tabs Required- Work for battery Only */}
          {BESSNavTabs && <Tabs variant={"soft-rounded"} colorScheme="gray">
            <TabList>
              <Tab 
                fontWeight={600} 
                color={"#004F86"} 
                fontFamily={"inter"} 
                fontSize={[10, 16]}
                as={Link}
                to={"/bess/overview"}
                bg = {location.pathname == "/bess/overview" ? "#D9D9D9" : ""}
              >BESS Overview</Tab>
              <Tab 
                fontWeight={600} 
                color={"#004F86"} 
                fontFamily={"inter"} 
                fontSize={[10, 16]}
                as={Link}
                to={"/bess/kpi"}
                bg={location.pathname=="/bess/kpi"? "#D9D9D9" : ""}
              >KPI</Tab>
              <Tab 
                fontWeight={600} 
                color={"#004F86"} 
                fontFamily={"inter"} 
                fontSize={[10, 16]}
                as={Link}
                to={"/bess/health"}
                bg={location.pathname == "/bess/health" ?  "#D9D9D9" : ""}
              >Health</Tab>
            </TabList>
          </Tabs>}
        </Flex>
        <Flex align="center">
          <IconButton
            aria-label="Color Switcher"
            display={["none", "flex"]}
            icon={colorMode === "light" ? <MdDarkMode /> : <MdLightMode />}
            onClick={toggleColorMode}
            cursor="pointer"
            mr="15"
            boxSize="25px"
          />
          <NotificationPopup>
            <Icon color="gray.500" as={FaBell} cursor="pointer" boxSize="20px" />
          </NotificationPopup>
          <Flex alignItems="center" display={["none", "flex"]}>
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: "none" }}
              >
                <HStack>
                  <Icon
                    as={FaCircleUser}
                    color="gray.500"
                    cursor="pointer"
                    ml="4"
                    boxSize="25px"
                  />
                  <VStack
                    display={{ base: "none", md: "flex" }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2"
                  >
                    <Text
                      fontSize="sm"
                      textTransform="capitalize"
                      fontWeight="600"
                      letterSpacing="1px"
                      fontFamily="sans-serif"
                    >
                      {fname + " " + lname}
                    </Text>
                    <Text
                      fontSize="xs"
                      color="gray.600"
                      textTransform="lowercase"
                      fontWeight="500"
                    >
                      {user.replace("_", " ")}
                    </Text>
                  </VStack>
                  <Box display={{ base: "none", md: "flex" }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList>
                <MenuItem
                  color="red.500"
                  fontWeight="700"
                  onClick={handleLogOut}
                >
                  <Icon as={FaSignOutAlt} color="gray.500" mr="5" />
                  Log out
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Flex>
      <Box as="main" p="4" pr={0} ml={isSidebarExpanded ? [5, 0] : [5, 0]} mt="14" onMouseEnter={handleChildBox}>
        {children}
      </Box>
    </Box>
  );
};

export default Navbar;


// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Flex,
//   IconButton,
//   Image,
//   Icon,
//   HStack,
//   VStack,
//   MenuButton,
//   Menu,
//   MenuList,
//   MenuItem,
//   Text,
//   useColorMode,
//   FormControl,
//   FormLabel,
//   Tabs,
//   TabList,
//   Tab,
// } from "@chakra-ui/react";
// import { MdDarkMode, MdLightMode } from "react-icons/md";
// import { FaBell, FaSignOutAlt } from "react-icons/fa";
// import { FaCircleUser } from "react-icons/fa6";
// import { FiChevronDown } from "react-icons/fi";
// import { jwtDecode } from "jwt-decode"; // Corrected import
// import { useLocation, useNavigate } from "react-router";
// import SidebarContent from "./SidebarContent";
// import useAuth from "../../Services/Hooks/UseAuth";
// import cogneta_logo from "../../assets/cogneta_logo.png";
// import cogneta from "../../assets/cogneta.png";
// import { Select } from "chakra-react-select";
// import { useCustomerOptionsContext } from "../../Context/CustomerOptionsContext";
// import { useSelectedCustomerIDContext } from "../../Context/SelectedCustomerIDContext";
// import { Link } from "react-router-dom";
// import { useBESSNavTabsContext } from "../../Context/BESSNavTabsContext";
// import NotificationPopup from "./NotificationPopup";
// import { PlantTimeZoneSelection } from "../../Services/CustomerHandlingService";
// import { EnlargeWidgetSize, EnlargeWidgetSizeWidth } from "../../Services/EnlargeWidget";

// interface JwtHeader {
//   firstName: string;
//   lastName: string;
//   scopes: string[];
// }

// const Navbar: React.FC<{ children: React.JSX.Element }> = ({ children }) => {
//   const jwt = localStorage.getItem("token");
//   if (!jwt) {
//     throw new Error("JWT not found in localStorage");
//   }

//   let fname = "";
//   let lname = "";
//   let user = "";

//   try {
//     const decodedToken: JwtHeader = jwtDecode<JwtHeader>(jwt);
//     const { firstName, lastName, scopes } = decodedToken;
//     fname = firstName;
//     lname = lastName;
//     user = scopes[0];
//   } catch (error) {
//     console.error("Error decoding token:", error);
//   }

//   const navigate = useNavigate();
//   const location = useLocation();
//   const { colorMode, toggleColorMode } = useColorMode();
//   const { logout } = useAuth();
//   const [isSidebarExpanded, setSidebarExpanded] = useState(false);
  
//   const handleLogOut = async () => {
//     const response = await logout();
//     if (response === true) {
//       navigate("/");
//     }
//   };
  
//   const handleToggleSidebar = () => {
//     setSidebarExpanded(!isSidebarExpanded);
//   };
  
//   const { customerOptions } = useCustomerOptionsContext();
//   const {selectedCustomerID, setSelectedCustomerID} = useSelectedCustomerIDContext();

//   const handlePlantChange = (selectedOption: any) => {
//     setSelectedCustomerID(selectedOption?.value);
//     localStorage.setItem("SelectedCustomerId", selectedOption?.value || "");
//       PlantTimeZoneSelection(selectedOption?.value);

//     window.location.reload();
//   };
//   // console.log(customerOptions)
//   // console.log(selectedCustomerID)
//   const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(null);
//   useEffect(() => {
//     if (customerOptions) {
//       // console.log(customerOptions)
//       setSelectedCustomerId(selectedCustomerID);
//       localStorage.setItem("SelectedCustomerId", selectedCustomerID || "");
//       if(selectedCustomerID) {
//         PlantTimeZoneSelection(selectedCustomerID);
//       }
//     } 
//   }, []);

//   const { BESSNavTabs } = useBESSNavTabsContext();

//   // ******************* Mouse Child Event********
//   const handleChildBox = () => {
//     if(isSidebarExpanded) {
//       setSidebarExpanded(false)
//     }
//   }
//   return (
//     <Box as="section" bg="#F2F3F8" _dark={{ bg: "gray.700" }} minH="100vh">
//       <SidebarContent
//         isSidebarExpanded={isSidebarExpanded}
//         onToggleSidebar={handleToggleSidebar}
//         display={{ base: "block", md: "block" }}
//         boxShadow="rgba(0, 0, 0, 0.8)"
//         bgColor="#001C3C"
//       />
//       <Flex
//         as="header"
//         align="center"
//         justify="space-between"
//         w="full"
//         pos="fixed"
//         top={0}
//         left={0}
//         px="4"
//         pl="0"
//         bg="white"
//         _dark={{ bg: "gray.800" }}
//         borderBottomWidth="1px"
//         color="inherit"
//         h={EnlargeWidgetSize(55) + "px"}
//         boxShadow="rgba(0, 0, 0, 0.06)"
//         zIndex={9999}
//       >
//         <Flex px={["2","4"]} py="5" align="center">
//           <Image src={cogneta_logo} alt="logo" w={["11", EnlargeWidgetSizeWidth(200) + "px"]} h={["10", EnlargeWidgetSize(80) + "px"]} display={["none", "block"]} />
//           <Image src={cogneta} alt="logo" w={["10", EnlargeWidgetSizeWidth(200) + "px"]} h={["10", EnlargeWidgetSize(80) + "px"]} mr={2} display={["block", "none"]} />
//           <FormControl
//             ml={[1, 5]}
//             px={[1, 5]}
//             width={[200, EnlargeWidgetSizeWidth(300)]}
//             h={14}
//             borderLeft={"1px solid #D1D8DD"}
//             // display={["none", "block"]}
//           >
//             <FormLabel
//               fontFamily={"inter"}
//               fontSize={EnlargeWidgetSize(10)}
//               color={"#747474"}
//               mt={2}
//             >
//               Select Plant
//             </FormLabel>
//             <Box mt={EnlargeWidgetSize(-3)} fontWeight={600} fontSize={EnlargeWidgetSize(12) + "px"}>
//               <Select
//                 isMulti={false}
//                 name="plants"
//                 placeholder="Select Any Plant"
//                 closeMenuOnSelect={true}
//                 variant="unstyle"
//                 size={"lg"}
//                 focusBorderColor="transparent"
//                 options={customerOptions || []}
//                 value={customerOptions?.find(option => option.value === localStorage.getItem("SelectedCustomerId"))}
//                 onChange={handlePlantChange}
//                 useBasicStyles={true}
//               />
//             </Box>
//           </FormControl>
//           {/* For Battery - Tabs Required- Work for battery Only */}
//           {BESSNavTabs && <Tabs variant={"soft-rounded"} colorScheme="gray">
//             <TabList>
//               <Tab 
//                 fontWeight={600} 
//                 color={"#004F86"} 
//                 fontFamily={"inter"} 
//                 fontSize={[10, EnlargeWidgetSize(16)]}
//                 as={Link}
//                 to={"/bess/overview"}
//                 bg = {location.pathname == "/bess/overview" ? "#D9D9D9" : ""}
//               >BESS Overview</Tab>
//               <Tab 
//                 fontWeight={600} 
//                 color={"#004F86"} 
//                 fontFamily={"inter"} 
//                 fontSize={[10, EnlargeWidgetSize(16)]}
//                 as={Link}
//                 to={"/bess/kpi"}
//                 bg={location.pathname=="/bess/kpi"? "#D9D9D9" : ""}
//               >KPI</Tab>
//               <Tab 
//                 fontWeight={600} 
//                 color={"#004F86"} 
//                 fontFamily={"inter"} 
//                 fontSize={[10, EnlargeWidgetSize(16)]}
//                 as={Link}
//                 to={"/bess/health"}
//                 bg={location.pathname == "/bess/health" ?  "#D9D9D9" : ""}
//               >Health</Tab>
//             </TabList>
//           </Tabs>}
//         </Flex>
//         <Flex align="center">
//           <IconButton
//             aria-label="Color Switcher"
//             display={["none", "flex"]}
//             icon={colorMode === "light" ? <MdDarkMode /> : <MdLightMode />}
//             onClick={toggleColorMode}
//             cursor="pointer"
//             mr="15"
//             boxSize={25}
//           />
//           <NotificationPopup>
//             <Icon color="gray.500" as={FaBell} cursor="pointer" boxSize="20px" />
//           </NotificationPopup>
//           <Flex alignItems="center" display={["none", "flex"]}>
//             <Menu>
//               <MenuButton
//                 py={2}
//                 transition="all 0.3s"
//                 _focus={{ boxShadow: "none" }}
//               >
//                 <HStack>
//                   <Icon
//                     as={FaCircleUser}
//                     color="gray.500"
//                     cursor="pointer"
//                     ml="4"
//                     boxSize="25px"
//                   />
//                   <VStack
//                     display={{ base: "none", md: "flex" }}
//                     alignItems="flex-start"
//                     spacing="1px"
//                     ml="2"
//                   >
//                     <Text
//                       fontSize={EnlargeWidgetSize(14)}
//                       textTransform="capitalize"
//                       fontWeight="600"
//                       letterSpacing="1px"
//                       fontFamily="sans-serif"
//                     >
//                       {fname + " " + lname}
//                     </Text>
//                     <Text
//                       fontSize={EnlargeWidgetSize(12)}
//                       color="gray.600"
//                       textTransform="lowercase"
//                       fontWeight="500"
//                     >
//                       {user.replace("_", " ")}
//                     </Text>
//                   </VStack>
//                   <Box display={{ base: "none", md: "flex" }}>
//                     <FiChevronDown />
//                   </Box>
//                 </HStack>
//               </MenuButton>
//               <MenuList>
//                 <MenuItem
//                   color="red.500"
//                   fontWeight="700"
//                   onClick={handleLogOut}
//                   fontSize={EnlargeWidgetSize(14)}
//                 >
//                   <Icon as={FaSignOutAlt} color="gray.500" mr="5" />
//                   Log out
//                 </MenuItem>
//               </MenuList>
//             </Menu>
//           </Flex>
//         </Flex>
//       </Flex>
//       <Box as="main" p="4" pr={0} ml={isSidebarExpanded ? [EnlargeWidgetSize(5), 0] : [EnlargeWidgetSize(5), 0]} mt={EnlargeWidgetSize(50)} onMouseEnter={handleChildBox}>
//         {children}
//       </Box>
//     </Box>
//   );
// };

// export default Navbar;

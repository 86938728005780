import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsFullScreen from 'highcharts/modules/full-screen';
import HighchartsReact from 'highcharts-react-official';

Highcharts3D(Highcharts); // Initialize the 3D module
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsFullScreen(Highcharts);

interface ColumnChartProps {
  apiData? : Object[];
  height? : Number | string;
  bg? : string;
  category? : string[];
  legendHeight? : number;
  xAxisInterval? : number | string;
  props? : any;
  isFullscreen? : boolean;
}

const ColumnChart : React.FC <ColumnChartProps> = ({apiData, height=280, bg, category, legendHeight=40, xAxisInterval="auto", props, isFullscreen=false}) => {
// console.log(category)
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'column',
      backgroundColor : bg || "transparent",
      height: height,
      // options3d: {
      //   enabled : true,
      //   alpha: 20, // Adjust this to control the tilt
      //   beta: 0,  // Adjust this to control the rotation
      //   depth: 0, // Adjust this to control the depth of the bars
      //   // viewDistance: 25
      // },

    },
    title: {
      text: null
    },
    series: [] as Object[],
    plotOptions: {
      column: {
        dataLabels: {
          enabled: false
        },
        pointWidth : 15,
        pointPadding : 0.5,
        borderWidth : 1,
        borderRadius : 25
      }
    },
    legend: {
      enabled: true,
      align: 'left',
      verticalAlign: 'bottom',
      maxHeight : legendHeight,
      useHTML : true,
      itemStyle : {
        overflow : "auto"
      }
    },
    tooltip : {
      shared : true,
      pointFormat: '{series.name}: {point.y}<br/>'
      // formatter: function (this : any) {
      //   var date = this.x // + (5.5 * 3600 * 1000); // Add 5.5 hours (IST) to the timestamp
      //   var formattedDate = Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', date);
      //   var points = this.points.map(function (point : any) {
      //       return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + point.y + '</b>';
      //   });
      //   return '<b>' + formattedDate + '</b><br/>' + points.join('<br/>');
      // }
      
    },
    xAxis : {
      labels : {
        enabled : true,
        tickInterval : xAxisInterval,
        // min: 1731715080000,
        // formatter: function(this : any) {
        //     return Highcharts.dateFormat('%H:%M', this.value + (5.5 * 3600 * 1000)); // Add 5.5 hours (IST) to the timestamp
        // }
      //   formatter: function (this: any) {
      //     const date = new Date(this.value);
      //     const day = date.getDate() - 1; // Subtract one day
      //     const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      //     const month = monthNames[date.getMonth()];
      //     return `${day} ${month}`;
      // }
      
      },
      type : 'datetime',
      gridLineWidth: 0,
      dateTimeLabelFormats: {
        month: '%b %y', // Custom format for months
        year: '%Y' // Format for years if needed
      },
    },
    yAxis : {
      title : {
        text: null
      },
      plotLines: [{
        color: '#000000',
        width: 2,
        value: -0,
        zIndex: 5 
      }],
      gridLineWidth: 2
    },
    exporting : {
      enabled : true,
      buttons: {
          contextButton: {
              menuItems: [
                  'downloadJPEG', // Allow JPEG export
                  'separator', 
                  'downloadCSV'   // Allow CSV export
              ]
          }
      }
    },
    credits: {
      enabled: false
    }
  });

  // Adjust chart height based on full-screen state
  useEffect(() => {
    const newHeight = isFullscreen ? '115%' : height;
    setChartOptions((prevOptions) => ({
        ...prevOptions,
        chart: {
            ...prevOptions.chart,
            height: newHeight
        }
    }));
}, [isFullscreen, height]);

  
  useEffect(() => {
    if (apiData) {
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        series: apiData,
        ...props
      }));

      if (category) {
        setChartOptions((prevOptions) => ({
          ...prevOptions,
          xAxis : {
          ...prevOptions.xAxis,
            categories: category
          }
        }));
      }
    }
  }, [apiData, category, props]);

    return (
    <>
    <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </>

  );
}
export default ColumnChart;
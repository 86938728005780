export const EnlargeWidgetSize = (baseSize  : number) : number => { // This is for Height only
    var screenHeight = window.screen.height;
    var increasePercent = baseSize / 864;   // 864px is Last responsive breakpoint for height in application.
    return (screenHeight * increasePercent);
};

export const EnlargeWidgetSizeWidth = (baseWidth : number) : number => {
    var screenWidth = window.screen.width;
    var increasePercent = baseWidth / 1536;  // 1536px is Last responsive breakpoint fior Width in application.
    return (screenWidth * increasePercent);
};

export const EnlargeWidgetContent = (baseHeightOfWidget : number, baseHeightofContent : number) : number => {
    var widgetHeight = EnlargeWidgetSize(baseHeightOfWidget);
    var WidgetContentPercentToEnlarge = baseHeightofContent / baseHeightOfWidget;
    return (widgetHeight * WidgetContentPercentToEnlarge);
}; 

export const EnlargeWidgetContentWidth = (baseWidth : number, baseWidthofContent? : number) : number => { // Note : Date 12-12-24 => This function has some Error.
    var widgetWidth = EnlargeWidgetContentWidth(baseWidth);
    if(!baseWidthofContent) {
        return widgetWidth;
    } else {
        var WidgetContentWidthPercentToEnlarge = baseWidthofContent / baseWidth;
        return (widgetWidth * WidgetContentWidthPercentToEnlarge);
    }
};
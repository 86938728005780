import { useState, useEffect, useCallback, useRef } from 'react';
import PlantTableAPI, { PlantAssetTableAPI } from '../../api/plantTableAPI';


const UsePlantTable = (searchTag : Object, textSearch : string, plant : string | null) => {

  const [data, setData] = useState(null);
  const hasFetchedRef = useRef(false);
  const fetchTelemetryData = useCallback(async () => {
    try {
      const response = await PlantTableAPI(searchTag, textSearch, plant);
      setData(response?.data);
    } catch (error) {
      console.error('Error fetching telemetry data:', error);
    }
  }, []);

  useEffect(() => {
    // if (!hasFetchedRef.current) {
        fetchTelemetryData();
    //     hasFetchedRef.current = true;
    // }
    const intervalId = setInterval(() => {
      const currentIntervalTime = new Date().getMinutes();
      const latestStoredTime = parseInt(localStorage.getItem("landingTime") || "0");
      
      if((currentIntervalTime == new Date(latestStoredTime - 300000).getMinutes()) || (currentIntervalTime == new Date(latestStoredTime).getMinutes())) {
          
          fetchTelemetryData();
      }

    }, 60000);

    // Cleanup interval on component unmount
    return () => {
        
        clearInterval(intervalId)
    };
  }, [fetchTelemetryData, 60000]);

  return data;
};

export default UsePlantTable;


const UsePlantAssetTable = (searchTag : Object, textSearch : string) => {

  const [data, setData] = useState(null);
  const hasFetchedRef = useRef(false);
  const fetchTelemetryData = useCallback(async () => {
    try {
      const response = await PlantAssetTableAPI(searchTag, textSearch);
      setData(response?.data);
    } catch (error) {
      console.error('Error fetching telemetry data:', error);
    }
  }, []);

  useEffect(() => {
    // if (!hasFetchedRef.current) {
        fetchTelemetryData();
    //     hasFetchedRef.current = true;
    // }
    const intervalId = setInterval(() => {
      const currentIntervalTime = new Date().getMinutes();
      const latestStoredTime = parseInt(localStorage.getItem("landingTime") || "0");
      
      if((currentIntervalTime == new Date(latestStoredTime - 300000).getMinutes()) || (currentIntervalTime == new Date(latestStoredTime).getMinutes())) {
          
          fetchTelemetryData();
      }

    }, 60000);

    // Cleanup interval on component unmount
    return () => {
        
        clearInterval(intervalId)
    };
  }, [fetchTelemetryData, 60000]);

  return data;
};

export {UsePlantAssetTable};

import { Box, Button, Flex, HStack, Image, Popover, PopoverAnchor, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Text } from "@chakra-ui/react";
import React from "react";
import UseInfoSymbol from "../../Services/Hooks/UseInfoSymbol";
import { EnlargeWidgetSize, EnlargeWidgetSizeWidth } from "../../Services/EnlargeWidget";

const InfoSymbol: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

    const infoKeys = ["Project", "Location", "Commissioned", "Energy System", "PV Capacity", "BESS Capacity", "DG Capacity"];
    const keys = ["image_url", "Plant_Name", "location", "commissioned", "Energy_System", "pv_capacity", "bess_capacity", "dg_capacity"];
    const data = UseInfoSymbol(keys);
  return (
    <Popover placement="left-end" isLazy>
      <PopoverTrigger>
        <Box as="span" display="inline-block">
          {children}
        </Box>
      </PopoverTrigger>
      <PopoverContent width={EnlargeWidgetSizeWidth(350) + "px"} height={"auto"} overflow={"hidden"} backdropFilter={"blur(13px) saturate(180%)"} bgColor={"rgba(255, 255, 255, 0.75)"}>
        <PopoverArrow />
        <PopoverHeader>
            <PopoverCloseButton />
        </PopoverHeader>
        <PopoverBody overflow={"auto"} scrollBehavior={"smooth"}>
            <Image src={data[0]} alt="PlantImage" />
            <Flex flexDirection={"column"}>
                {data.slice(1, data.length).map((value, i) => (
                    <HStack borderTop={"1px solid #999"} py={EnlargeWidgetSize(2) + "px"}>
                        <Text color={"black"} fontWeight={600} fontSize={EnlargeWidgetSize(14)}>{infoKeys[i]} : </Text>
                        <Text fontSize={EnlargeWidgetSize(12)}>{data[i+1]}</Text>
                    </HStack>
                ))}
            </Flex>
        </PopoverBody>
        {/* <PopoverFooter>

        </PopoverFooter> */}
      </PopoverContent>
    </Popover>
  );
};

export default InfoSymbol;

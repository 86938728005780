import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  HStack,
  Icon,
  IconButton,
  Portal,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { TimeWindow } from '../../../Services/TimeWindow';
import { RxCounterClockwiseClock } from 'react-icons/rx';
import { MdFullscreen } from 'react-icons/md';

interface TabsTableLayoutProps {
  tabs : string[];
  dotColors : string[];
  childrens : React.ReactNode[];
  title: string;
  bg? : string;
  width: string[];
  height: string | number;
  px? : string;
  fullscreen? : boolean;
  paginationLimitProps? : number;
  timeWindow? : boolean;
  onTimeWindowChange?: (from: string, to: string, aggregate: string, interval : number) => void;
  onReset? : (Reset : boolean) => void;
}

const TabsTableLayout: React.FC<TabsTableLayoutProps> = ({ tabs, dotColors, childrens, title, bg, width, height, px, timeWindow, fullscreen, paginationLimitProps=8, onTimeWindowChange, onReset }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const chartLayoutRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [paginationLimit, setPaginationLimit] = useState<number>(paginationLimitProps)
  // console.log(apiData)

  const handleFullScreen = () => {
    if (tableRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
        setIsFullscreen(false);
      } else {
        (tableRef.current as HTMLElement)?.requestFullscreen();
        setPaginationLimit(20); // Increase pagination limit in fullscreen mode
        setIsFullscreen(true);
      }
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setPaginationLimit(paginationLimitProps);
        setIsFullscreen(false); // Exited fullscreen
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [paginationLimitProps]);

  const [currentTab, setCurrentTab] = useState(0);

  return (
    <Box
      ref={tableRef}
      w={width}
      h={height}
      borderRadius="5px"
      bgColor={useColorModeValue('white', 'gray.800')}
      p={3}
      px={px}
      mt={5}
      boxShadow="md"
      overflow={"auto"}
      bg = {bg}
    >
      <HStack height={"40px"} spacing={5}>
        <Box mt={-5}>
          {timeWindow && <IconButton
                  aria-label='Time Window'
                  icon={<RxCounterClockwiseClock />}
                  onClick={onOpen}
          />}
          {/* Use Portal to render the modal within the fullscreen container */}
        <Portal containerRef={chartLayoutRef}>
            <TimeWindow 
                isOpen={isOpen} 
                onClose={onClose} 
                onSave={onTimeWindowChange || (()=>{})} 
                onReset={onReset || (() => {})}
            />
        </Portal>

        </Box>
        <Text 
         fontSize={"16px"}
         fontFamily={"inter"} 
         fontWeight={600} 
         color={useColorModeValue('#004F86', 'white')}
         letterSpacing={1}
         mt ={-5}
         >
            {title}
        </Text>

         <Tabs isFitted variant={"soft-rounded"} colorScheme='purple' mt={-5}>
             <TabList bgSize={"xs"}>
            {
                tabs.map((value, i) => (
                    <Tab 
                        mx={1} 
                        px={3}
                        fontSize={"xs"}
                        onClick={() => {setCurrentTab(i)}}
                        >
                        <span 
                            style={{width:"10px",height:"10px",backgroundColor:dotColors[i].toLowerCase(), display:"flex", borderRadius:"50%", marginRight:"7px"}}
                            ></span>
                        <Text>{value}</Text>
                    </Tab>
                ))
            }
            </TabList>
         </Tabs>
      </HStack>
            <Box as='main' pb={6} height={"full"} width={"full"} _dark={{color : "white"}} ref={chartLayoutRef}>
                {/* Pass the isFullscreen state to children */}
                { childrens.map((children, index) => currentTab == index ? (
                  (React.isValidElement(children) &&
                  React.cloneElement(children as React.ReactElement<any>, {
                  isFullscreen,
                  }))
                  ) : " ")
                }

                {fullscreen && <Icon 
                as={MdFullscreen}
                boxSize={5} 
                float={"right"} 
                pos={"relative"} 
                bottom={"42px"} 
                onClick={handleFullScreen}
                _fullScreen={{
                    bottom : "45px"
                }}
                cursor={"pointer"}
                />}
            </Box>
    </Box>
  );
};

export default TabsTableLayout;

import { Box, Grid, GridItem } from "@chakra-ui/react";
import { Fieldset_Devices, Fieldset_kW52860, Fieldset_Mode, Fieldset_Plant, Fieldset_Meter, Fieldset_Temp } from "./widgets/FieldsetContent";
import UseBatteryStatus from "../Services/Hooks/Battery/UseBatteryStatus";
import { EnlargeWidgetSize } from "../Services/EnlargeWidget";

const TopRibbon = () => {

    const searchAC = {
        devName : "Calculation",
        keys : "CUF_AC"
    };
    const ACData = UseBatteryStatus(searchAC) || [0];

    const searchDC = {
        devName : "Calculation",
        keys : "CUF_DC"
    };
    const DCData = UseBatteryStatus(searchDC) || [0];

    return(
        <Box
                display={{base : "none", sm : "none", md : "none", lg : "none", xl : "block", '2xl' : "block"}}
                height={EnlargeWidgetSize(80) + "px"}
            >
                <Grid
                    h={["300px","200px","200px","130px","60px","60px"]}
                    templateRows={["repeat(6, 1fr)", "repeat(3, 1fr)", "repeat(3, 1fr)", "repeat(2, 1fr)", "repeat(1, 1fr)", "repeat(1, 1fr)"]}
                    templateColumns={["repeat(1, 1fr)","repeat(2, 1fr)","repeat(2, 1fr)","repeat(3, 1fr)","repeat(6, 1fr)","repeat(6, 1fr)"]}
                    gap={[5,5,5,1,1]}
                >
                    <GridItem w={"auto"} fontSize={[7, 7, 7, 9, 12, (EnlargeWidgetSize(12) * 1.092) - 1.5 ]} h={59}>
                        <Fieldset_kW52860
                            CUF_AC={typeof ACData[0] == "string" ? (parseFloat(ACData[0]).toFixed(2)) : ACData[0]}
                            CUF_DC={typeof DCData[0] == "string" ? (parseFloat(DCData[0]).toFixed(2)) : DCData[0]}
                            mod_temp={0}
                            GHI={0}
                            POA={0}
                            Tilt={0}
                            PR_Actual={0}
                        />
                    </GridItem>
                    <GridItem w={"auto"} fontSize={[7, 7, 5, 7, 12, EnlargeWidgetSize(12)]} h={58}>
                        <Fieldset_Mode />
                    </GridItem>
                    <GridItem w={"auto"} fontSize={[7, 7, 5, 10, 12, EnlargeWidgetSize(12)]} h={59}>
                        <Fieldset_Plant />
                    </GridItem>
                    <GridItem w={"auto"} fontSize={[7, 7, 7, 10, 12, EnlargeWidgetSize(12)]} h={58}>
                        <Fieldset_Meter />
                    </GridItem>
                    <GridItem w={"auto"} fontSize={[7, 7, 7, 9, 12, EnlargeWidgetSize(12)]} h={58}>
                        <Fieldset_Temp />
                    </GridItem>
                    <GridItem w={"auto"} fontSize={[7, 7, 7, 10, 12, (EnlargeWidgetSize(12) * 0.85) + 1.5]}>
                        <Fieldset_Devices />
                    </GridItem>
                </Grid>
            </Box>
    );
};

export default TopRibbon;
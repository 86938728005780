import { lazy, Suspense, useState } from "react";
import {
  Box, 
  Grid, 
  GridItem,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  SimpleGrid,
  Flex
} from "@chakra-ui/react";

import { IoLocation } from "react-icons/io5";
import { FaChartBar, FaChartColumn } from "react-icons/fa6";
import { PiChartBar, PiChartDonutFill } from "react-icons/pi";
import { MdGrid4X4 } from "react-icons/md";
import { Link } from "react-router-dom";
import { FaCaretRight } from "react-icons/fa";
import UsePieChart from "../Services/Hooks/UseSourceContribution";
import ChartLayout from "../components/Layouts/ChartLayouts/ChartLayout";
import PlantTable from "../components/widgets/tables/PlantTable";
import StatisticsCard from "../components/widgets/StatisticsCard";
import LocationMapChart from "../components/widgets/charts/LocationMapChart";
import PlantTableLayout from "../components/Layouts/TableLayouts/PlantTableLayout";
import BarChart from "../components/widgets/charts/BarChart";
import ColumnLineChart from "../components/widgets/charts/ColumnLineChart";
import UseAssetSummary from "../Services/Hooks/UseAssetSummary";
import UseColumnLine from "../Services/Hooks/UseColumnLine";
import UseLocationMap from "../Services/Hooks/UseLocationMap";
import { useTimeHandle } from "../Services/TimeWindowSetting";
import ColumnChart from "../components/widgets/charts/ColumnChart";
import UsePlantsManyDeviceSameKey from "../Services/Hooks/UsePlantsManyDeviceSameKey";
import UsePortfolioPlantsStackedAvailability from "../Services/Hooks/UsePortfolioPlantsStackedAvailability";
import TimeDisplay from "../components/TimeDisplay";
import TabsChartLayout from "../components/Layouts/ChartLayouts/TabsChartLayout";
import LandingTimeService from "../Services/LandingTimeService";
import { EnlargeWidgetContent, EnlargeWidgetSize } from "../Services/EnlargeWidget";
const DonutPieChart = lazy(() => import('../components/widgets/charts/DonutPieChart'));

const jp = require('jsonpath');


const MainDashboard = () => {

  // ************ Landing Time Process **********
  LandingTimeService();
  
  const [timeWindow, setTimeWindow] = useState({ startTs: 0, endTs: 0, aggregate: "NONE" });

    const handleTimeWindowChange = (from: string, to: string, aggregate: string) => {
        function inMS (date:string) {
          return new Date(date).getTime();
        }
        var startTs = inMS(from)
        var endTs = inMS(to)

        setTimeWindow({ startTs, endTs, aggregate });
        // console.log(from, to, aggregate)
        // Fetch and update the data based on the new time window
    };

    // ******************LOCATION MAP*********************

    const searchLocationTag = "Plant_Name,AC_Capacity,Latitude,Longitude"
    
    const LocationMapData = UseLocationMap(searchLocationTag);


    // ********************Specific Yield / Ranking Bar Chart ********************

    const {
      timeWindow: timeWindowSpecific,
      handleTimeWindowChange: handleTimeWindowSpecificChange,
      handleReset: SpecificHandleReset
  } = useTimeHandle(5, "mintue", "AVG", [5, "minute"]);
    var typeSpecific = "bar"
    var searchSpecific = {calculation : "Specific_Yield"};      //"BP_Plant_Daily_Energy"; this was previous tag
    const specificYieldData = UsePlantsManyDeviceSameKey(typeSpecific, searchSpecific, [""], timeWindowSpecific);

    // ***********************Energy Yield******************
    // var textSearch = "portfolio";
    // const type = { // Line for PV PR only
    //     column : "PV_Portfolio_Energy,Grid_Portfolio_Daily_Energy_Export",
    //     line : "B1_Inverter_Inverter_1_AC_Active_Power_Watt,B1_Inverter_Inverter_1_Energy_Total_kWh,B1_Inverter_Inverter_1_Frequency_Hz,B1_Inverter_Inverter_1_AC_Active_Power_Watt,B1_Inverter_Inverter_1_Energy_Total_kWh,B1_Inverter_Inverter_1_Frequency_Hz,B1_Inverter_Inverter_1_Energy_Total_kWh",
    // };
    // const ColumnLineData = UseColumnLine(textSearch, type) || [{}];

    const {
      timeWindow: timeWindowEnergyYield,
      handleTimeWindowChange: handleTimeWindowEnergyYieldChange,
      handleReset: EnergyYieldHandleReset
  } = useTimeHandle(7, "day", "NONE", [5, "minute"]);

  var searchTagEnergyYield = { 
    devName : "portfolio",
    keys: "PV_Portfolio_Energy,Grid_Portfolio_Daily_Energy_Export",
    type : ["bar", "bar"],
    name : ["Daily Energy", "Energy Consumption"]
  };

  const ColumnLineData = UseColumnLine(searchTagEnergyYield, timeWindowEnergyYield);


  // ********************* Plant Downtime *********************
    const {
        timeWindow: timeWindowPlantDowntime,
        handleTimeWindowChange: handleTimeWindowPlantDowntimeChange,
        handleReset: PlantDowntimeHandleReset
    } = useTimeHandle(1, "cmsf", "COUNT", [1, "month"]);
    
    
  var textSearchPlantDowntime = [""];
  var searchTagsPlantDowntime = {
    calculation : "Plant_Down_instance"
  }
  var PlantDowntimeType = "column"

    const PlantDowntime = UsePlantsManyDeviceSameKey(PlantDowntimeType,searchTagsPlantDowntime, textSearchPlantDowntime, timeWindowPlantDowntime);
    const PlantDowntimeValues = PlantDowntime? (jp.query(PlantDowntime, "$..data")).flat(1) : [0];
    const AVGPlantDowntime = PlantDowntimeValues.reduce((sum : number, current : number) => {return sum + current}) / PlantDowntimeValues.length;
    // console.log(PlantDowntime? jp.query(PlantDowntime, "$..name") : []);

    // ********************** Portfolio Plants Availability ****************

    const {
      timeWindow: timeWindowProtfolioPlantsAvailability,
      handleTimeWindowChange: handleTimeWindowProtfolioPlantsAvailabilityChange,
      handleReset: ProtfolioPlantsAvailabilityHandleReset
  } = useTimeHandle(1, "cmsf", "NONE", [6.5, "month"]);

  const ProtfolioPlantsAvailabilityType = "line"
  const textSearchProtfolioPlantsAvailability = [""];

    const ProtfolioPlantsAvailabilityData = UsePortfolioPlantsStackedAvailability(ProtfolioPlantsAvailabilityType, textSearchProtfolioPlantsAvailability, timeWindowProtfolioPlantsAvailability);
    
    // *********PIE*************

    // ********** Source Contribution ***********
    // Daily
    var textSearch = "Portfolio";
    var key = "PV_Contribution,Wind_Total_Power,PCS_Contribution,DG_Contribution";
    var title = ["PV", "Wind", "BESS", "DG"];
    var resolutionDaily = "Daily";
    const SourceContributionDaily = UsePieChart(textSearch, key, title, resolutionDaily) || [];

    var resolutionWeekly = "Weekly";
    const SourceContributionWeekly = UsePieChart(textSearch, key, title, resolutionWeekly) || [];

    var resolutionMonthly = "Monthly";
    const SourceContributionMonthly = UsePieChart(textSearch, key, title, resolutionMonthly) || [];

    // ************** Asset Summarry **************
    
    var searchObj = {
      inverter : `AC_Active_Power_Watt`,
      DG : `AC_Active_Power_Watt`,
      PCS : `AC_Active_Power_Watt`,
      Wind : `Wind_power`
    }
    const AssetSummary = UseAssetSummary(searchObj) || [];
    
    // ********* Runtime Format hr:min ********
    function runtimeFormat(runtime: number): string {
      // Get the total number of hours
      const hour = Math.floor(runtime / 60);
      
      // Get the remaining minutes
      const min = Math.round(runtime % 60);
  
      // Return formatted result
      return `${hour}h ${min}m`;
  }
    return (
      <Box maxW="full" ml={10} px={{ base: 2, sm: 12, md: 17 }}>
        <Flex justify={"space-between"} >
          <Breadcrumb spacing="8px" separator={<FaCaretRight color="gray.500" />} mb={5}>
            <BreadcrumbItem>
              <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontSize={EnlargeWidgetSize(12)} fontWeight={600} as={Link} to="/">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontSize={EnlargeWidgetSize(12)} fontWeight={600} as={Link} to="/portfolio">
                Portfolio
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>

          <TimeDisplay />
        </Flex>
      <StatisticsCard />
      {/* <SimpleGrid
        // mt={10}
        maxW={"9xl"}
        // h="max"
        // minChildWidth={["260px", "400px"]}
        // templateRows="repeat(2, 1fr)"
        // templateColumns="repeat(3, 1fr)"
        gap={1}
      > */}
        <SimpleGrid
         maxW={"9xl"}
         minChildWidth={["260px", "400px"]}
         gap={1}
         >
          <GridItem rowSpan={[1, 1]} colSpan={[3, 1]}>
            <ChartLayout
              title="Location Map"
              width={["auto", "auto"]}
              height={["270px", "270px", "270px", "270px", EnlargeWidgetSize(270) + "px"]}
              icon={IoLocation}
              fullScreen={true}
              onTimeWindowChange = {handleTimeWindowChange}
            >
              <LocationMapChart apiData={LocationMapData || [{country : "", lat : 0, long : 0, name : "", type : ""}]} />
            </ChartLayout>
          </GridItem>
          <GridItem rowSpan={[1, 1]} colSpan={[3, 1]}>
            <ChartLayout
              title="Specific Yield"
              initTimeTitle="Current Day"
              width={["full", "auto"]}
              height={["270px", "270px", "270px", "270px", EnlargeWidgetSize(270) + "px"]}
              icon={FaChartBar}
              fullScreen = {true}
              onTimeWindowChange = {handleTimeWindowChange}
            >
              <BarChart apiData={specificYieldData || [{}]}
                barColors={["#0086CC", "#03BB7D"]}
                height={EnlargeWidgetContent(270, 240)}
                props={{
                  tooltip : {
                    formatter : function (this : any) {
                      var points = this.points.map(function (point : any) {
                        return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + point.y + '</b>';
                      });
                      return '<br/>' + points.join('<br/>');
                    }
                  }
                }}
              />
            </ChartLayout>
          </GridItem>
          <GridItem rowSpan={[1, 1]} colSpan={[3, 1]}>
            <ChartLayout
              title="Energy Yield"
              initTimeTitle="Last 7 Days"
              width={["full", "auto"]}
              height={["270px", "270px", "270px", "270px", EnlargeWidgetSize(270) + "px"]}
              icon={FaChartColumn}
              onTimeWindowChange = {handleTimeWindowChange}
              fullScreen={true}
            >
                <ColumnLineChart height={EnlargeWidgetContent(270, 250)} apiData={ColumnLineData || [{}]} // column line = energy yield
                  props = {{
                    legend: {
                      itemStyle: {
                          fontSize: '12px'
                      }
                  },
                  yAxis: [{
                    title: {
                        text: 'kWh'
                    }
                    }, {
                        title: {
                            text: 'PR %'
                        },
                        opposite: true
                    }],
                  }}
                />
            </ChartLayout>
          </GridItem>
         </SimpleGrid>
         <SimpleGrid
         maxW={"9xl"}
         minChildWidth={["260px", "400px"]}
         gap={1}
         >
          <GridItem rowSpan={[1, 1]} colSpan={[3, 1]} mt={-3}>
            <ChartLayout
              title="Plant Downtime"
              initTimeTitle="Current Month"
              width={["full", "auto"]}
              height={["270px", "270px", "270px", "270px", EnlargeWidgetSize(270) + "px"]}
              icon={PiChartBar}
              onTimeWindowChange = {handleTimeWindowChange}
              fullScreen = {true}
            >
            <ColumnChart height={EnlargeWidgetContent(270, 250)} apiData={[{name : "Instance", data :  PlantDowntime? jp.query(PlantDowntime, "$..data") : [{}]}]}
              props={{
                xAxis : {
                  categories : PlantDowntime? jp.query(PlantDowntime, "$..name") : []
                },
                yAxis : {
                  plotLines: [{
                    value: AVGPlantDowntime, // The value where the peak line will be drawn
                    color: 'red', // Color of the peak line
                    width: 2, // Width of the peak line
                    zIndex: 5, // Positioning the line above other elements
                    label: {
                        text: runtimeFormat(AVGPlantDowntime), // Label for the peak line
                        align: 'left',
                        verticalAlign: 'top',
                        style: {
                            color: 'red'
                        }
                    }
                  }]
                },
                tooltip : {
                  formatter: function (this : any) {
                    var points = this.points.map(function (point : any) {
                        return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + point.y + '</b>';
                    });
                    return '<br/>' + points.join('<br/>');
                  }
                }
              }}
            /> 
            </ChartLayout>
          </GridItem>
          <GridItem rowSpan={[1, 1]} colSpan={[3, 1]} mt={-3}>
            <ChartLayout
              title="Total Availability"
              initTimeTitle="Last 6 Month"
              width={["full", "auto"]}
              height={["270px", "270px", "270px", "270px", EnlargeWidgetSize(270) + "px"]}
              icon={FaChartColumn}
              onTimeWindowChange = {handleTimeWindowChange}
              fullScreen = {true}
            >
              <ColumnChart height={EnlargeWidgetContent(270, 250)} apiData={ProtfolioPlantsAvailabilityData || [{}]}
                props = {{
                  plotOptions : {
                    column : {
                      pointWidth : 30,
                      borderRadius : 5
                    }
                  },
                  yAxis : [{
                    title : {
                      text : "Availability",
                    },
                    labels : {
                      format : '{value} %'
                    }
                  },{
                    title : {
                      text : "kWh"
                    },
                    labels : {
                      format : '{value}'
                    },
                    opposite : true
                  }],
                }}
              />
            </ChartLayout>
          </GridItem>
          <GridItem rowSpan={[1, 1]} colSpan={[3, 1]} mt={-3}>
            <TabsChartLayout
              tabs={["Daily", "Weekly", "Monthly"]}
              dotColors={["green", "yellow", "#333"]}
              title="Source Contribution"
              width={["full", "auto"]}
              height={["270px", "270px", "270px", "270px", EnlargeWidgetSize(270) + "px"]}
              icon={PiChartDonutFill}
              onTimeWindowChange = {handleTimeWindowChange}
              mt="-10px"
              childrens={[
                <Suspense fallback={<div style={{position:"relative", top : "45%", left : "45%"}}>Loading...</div>}>
                  <DonutPieChart height={EnlargeWidgetContent(270, 250)} name="AVG %" apiData={SourceContributionDaily}
                    pieColors={["#704199", "#0086CC", "#66D1C9", "#F8931F",'#9b20d9', '#9215ac', '#861ec9', '#7a17e6', '#7010f9', '#691af3',
                      '#6225ed', '#5b30e7', '#533be1', '#4c46db', '#4551d5', '#3e5ccf',
                      '#3667c9', '#2f72c3', '#277dbd', '#1f88b7', '#1693b1', '#0a9eaa',
                      '#03c69b', '#00f194']}
                  />
                </Suspense>,
                <Suspense fallback={<div style={{position:"relative", top : "45%", left : "45%"}}>Loading...</div>}>
                  <DonutPieChart height={EnlargeWidgetContent(270, 250)} name="AVG %" apiData={SourceContributionWeekly}
                    pieColors={["#704199", "#0086CC", "#66D1C9", "#F8931F",'#9b20d9', '#9215ac', '#861ec9', '#7a17e6', '#7010f9', '#691af3',
                      '#6225ed', '#5b30e7', '#533be1', '#4c46db', '#4551d5', '#3e5ccf',
                      '#3667c9', '#2f72c3', '#277dbd', '#1f88b7', '#1693b1', '#0a9eaa',
                      '#03c69b', '#00f194']}
                  />
                </Suspense>,
                <Suspense fallback={<div style={{position:"relative", top : "45%", left : "45%"}}>Loading...</div>}>
                  <DonutPieChart height={EnlargeWidgetContent(270, 250)} name="AVG %" apiData={SourceContributionMonthly}
                    pieColors={["#704199", "#0086CC", "#66D1C9", "#F8931F",'#9b20d9', '#9215ac', '#861ec9', '#7a17e6', '#7010f9', '#691af3',
                      '#6225ed', '#5b30e7', '#533be1', '#4c46db', '#4551d5', '#3e5ccf',
                      '#3667c9', '#2f72c3', '#277dbd', '#1f88b7', '#1693b1', '#0a9eaa',
                      '#03c69b', '#00f194']}
                  />
                </Suspense>,
              ]}
            />
              
          </GridItem>
         </SimpleGrid>
         <SimpleGrid
         maxW={"9xl"}
         minChildWidth={["260px", "400px"]}
         gap={1}
         >
          <GridItem rowSpan={1} colSpan={[1, 2]} mt={-3}>
            <PlantTableLayout
              title="Assets"
              icon={MdGrid4X4}
              width={["full", "100%"]}
              height={["270px", "270px", "270px", "270px", EnlargeWidgetSize(270) + "px"]}
            >
              <PlantTable
                paginationLimitProps={10}
              />
            </PlantTableLayout>
          </GridItem>
          <GridItem rowSpan={[1, 1]} colSpan={[3, 1]} mt={-4}>
            <ChartLayout
              title="Asset Summary"
              width={["full", "auto"]}
              height={["270px", "270px", "270px", "270px", EnlargeWidgetSize(270) + "px"]}
              icon={PiChartDonutFill}
              onTimeWindowChange = {handleTimeWindowChange}
            >
              <Suspense fallback={<div style={{position:"relative", top : "45%", left : "45%"}}>Loading...</div>}>
                <DonutPieChart height={EnlargeWidgetContent(270, 250)} name="Source"  apiData={AssetSummary}
                  pieColors={["#704199", "#0086CC", "#66D1C9", "#F8931F",'#9b20d9', '#9215ac', '#861ec9', '#7a17e6', '#7010f9', '#691af3',
                    '#6225ed', '#5b30e7', '#533be1', '#4c46db', '#4551d5', '#3e5ccf',
                    '#3667c9', '#2f72c3', '#277dbd', '#1f88b7', '#1693b1', '#0a9eaa',
                    '#03c69b', '#00f194']}
                />
              </Suspense>
            </ChartLayout>
          </GridItem>
         </SimpleGrid>
      {/* </SimpleGrid> */}
    </Box>
  );
};

export default MainDashboard;

import { Box, Text } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { EnlargeWidgetSize } from '../Services/EnlargeWidget';

function TimeDisplay() {
  const [time, setTime] = useState(new Date());
  const currentUserTimeZone = localStorage.getItem("currentUserTimeZone");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box display={["none", "block"]}>
      <Text fontFamily={"inter"} fontSize={EnlargeWidgetSize(12)} fontWeight={600} color={"rgba(0, 79, 134, 1)"}>{time.toLocaleString()} ({currentUserTimeZone})</Text>
    </Box>
  );
}

export default TimeDisplay;